import { BLINDS } from "constant/constant";



export const GetUserFormSelectData = (users) => {
  let ret = [];
  users.forEach((user) => {
    let tm = {};
    tm["value"] = user["id"];
    tm["label"] = user["username"];    
    ret.push(tm);
  });
  return ret;
};

export const GetSortedStackList = (stackList) => {
  return stackList.sort((a, b) => {
    var aa = parseFloat(a["value"].split("|")[0].split("|")[0]);
    var bb = parseFloat(b["value"].split("|")[0].split("|")[0]);
    return aa - bb;
  });
};

export const GetStackConvertor = (site, union, stackconvertor) => {
  let retlist = [];
  stackconvertor.forEach((val) => {
    if (val["site_name"] === site) {
      if (val["site_unions"].length !== 0) {
        val["site_unions"].forEach((val1) => {
          if (val1["union_name"] == union) {
            val1["stackconvertors"].forEach((val2) => {
              let tm = {};
              tm["checked"] = false;
              tm["value"] = val2;
              retlist.push(tm);
            });
          }
        });
      }
    }
  });
  return GetSortedStackList(retlist);
};

export const GetNewStackConvertorByStacks = (
  stackconvertor,
  site,
  union,
  new_stacks
) => {
  let ret = [...stackconvertor];

  if (ret.length === 0) {
    let newstack = {};
    newstack.site_name = site;
    newstack.site_unions = [];
    newstack.site_unions.push({
      union_name: union,
      stackconvertors: [new_stacks],
    });
    ret.push(newstack);
  } else {
    let site_flag = true;
    ret.forEach((val) => {
      if (val["site_name"] === site) {
        site_flag = false;
        if (val["site_unions"].length !== 0) {
          let flag = true;
          val["site_unions"].forEach((val1) => {
            if (val1["union_name"] == union) {
              val1["stackconvertors"] = new_stacks;
              flag = false;
            }
          });
          if (flag) {
            val.site_unions.push({
              union_name: union,
              stackconvertors: [new_stacks],
            });
          }
        }
      }
    });
    if (site_flag) {
      let newstack = {};
      newstack.site_name = site;
      newstack.site_unions = [];
      newstack.site_unions.push({
        union_name: union,
        stackconvertors: [new_stacks],
      });
      ret.push(newstack);
    }
  }
  return ret;
};

export const GetNewStackConvertor = (
  stackconvertor,
  site,
  union,
  new_stack
) => {
  let ret = [...stackconvertor];

  if (ret.length === 0) {
    let newstack = {};
    newstack.site_name = site;
    newstack.site_unions = [];
    newstack.site_unions.push({
      union_name: union,
      stackconvertors: [new_stack],
    });
    ret.push(newstack);
  } else {
    let site_flag = true;
    ret.forEach((val) => {
      if (val["site_name"] === site) {
        site_flag = false;
        if (val["site_unions"].length !== 0) {
          let flag = true;
          val["site_unions"].forEach((val1) => {
            if (val1["union_name"] == union) {
              val1["stackconvertors"].push(new_stack);
              flag = false;
            }
          });
          if (flag) {
            val.site_unions.push({
              union_name: union,
              stackconvertors: [new_stack],
            });
          }
        }
      }
    });
    if (site_flag) {
      let newstack = {};
      newstack.site_name = site;
      newstack.site_unions = [];
      newstack.site_unions.push({
        union_name: union,
        stackconvertors: [new_stack],
      });
      ret.push(newstack);
    }
  }
  return ret;
};

export const IsExistInFormItemList = (formlist, value) => {
  let ret = false;
  formlist.forEach((val) => {
    if (val["value"] === value) ret = true;
  });
  return ret;
};

export const GetRealStackValues = () => {
  var ret = [];
  BLINDS.forEach((ele) => {
    var tmp = {};
    tmp["value"] = ele;
    tmp["label"] = ele;
    ret.push(tmp);
  });
  return ret;
};

export const GetRealStackValuesInAdvSetting = () => {
  var ret = [];
  var index = 0;
  BLINDS.forEach((ele) => {
    var tmp = {};
    tmp["value"] = index;
    tmp["label"] = ele;
    ret.push(tmp);
    index++;
  });
  return ret;
};
