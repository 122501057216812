import { configureStore } from "@reduxjs/toolkit";
import { loginReducer } from "./login.slice";
import { changeStateReducer } from "./setting.slice";
import { playerColorReducer } from "./playercolor.slice";
import { profilesReducer } from "./profiles.slice";
import { easyseatDataReducer } from "./easyseat_data.slice";
import { siteUnionClubReducer } from "./sites_unions_clubs.slice";
import { easySeatUsersReducer } from "./users.slice";
import { siteUnionClubDefaultReducer } from "./sites_unions_clubs.slice_default";
import { stackConvertorReducer} from "./stackconvertor.slice"
import { confirmDlgStateReducer} from "./confirm_slice"

export const store = configureStore({
  reducer: {
    login: loginReducer,
    profileState: profilesReducer,
    easyseatData: easyseatDataReducer,
    usersChangeState:easySeatUsersReducer,
    siteUnionClubState: siteUnionClubReducer,
    changeState: changeStateReducer,
    playerColorState: playerColorReducer,
    siteUnionClubDefaultState: siteUnionClubDefaultReducer,
    stackConvertorState:stackConvertorReducer,
    confirmDlgState:confirmDlgStateReducer,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});
