import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardHeader,
  CRow,
  CCol,
  CCardBody,
  CFormSelect,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CListGroup,
  CListGroupItem,
  CFormCheck,
} from "@coreui/react-pro";
import { useSelector, useDispatch } from "react-redux";
import {
  ShowSuccessToast,
  ShowFailToast,
  ShowWarningToast,
} from "../../helpers/toast-alert";
import { GetSitesWithUnion, GetUnions } from "helpers/admin/convert";
import { UpdateStackConvertor } from "helpers/admin/supabase";
import TreeView, { flattenTree } from "react-accessible-treeview";
import { IoMdArrowDropright } from "react-icons/io";
import cx from "classnames";
import FontAwesome from "react-fontawesome";
import {
  GetNewStackConvertor,
  GetNewStackConvertorByStacks,
  GetRealStackValues,
  GetStackConvertor,
  IsExistInFormItemList,
  GetSortedStackList,
} from "helpers/admin/transform";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

const StackConvertorPage = () => {

  const dispatch = useDispatch();
  const siteUnionClubs = useSelector((state) => state.siteUnionClubState.data);
  const stackConvertors = useSelector(
    (state) => state.stackConvertorState.data
  );

  let ini_sites = [];
  let inisite = "";
  let iniunions = [];
  let iniunion = "";
  let inistack = [];

  const [iseditable, SetIsEditable] = useState(false);
  const [sites, SetSites] = useState(ini_sites);
  const [site, SetSite] = useState(inisite);
  const [unions, SetUnions] = useState(iniunions);
  const [union, SetUnion] = useState(iniunion);
  const [isaddstack, SetIsAddstack] = useState(false);
  const [isremovestack, SetRemoveAddstack] = useState(false);
  const [stack, SetStack] = useState(inistack);
  const [unionstack, SetUnionstack] = useState("");
  const [realstack, SetRealstack] = useState("1/2");
  const [expanded, SetExpanded] = useState([]);
  const [nodes, SetNodes] = useState([]);

  let ini_expandednodes = [];
  let ini_nodes = {};

  const GetNodesForNoCheckBoxTreeView = (siteUnionsClubs) => {
    let tmnodes = { name: "", children: [] };
    siteUnionsClubs.forEach((val) => {
      let tsitenode = { name: "", children: [] };
      tsitenode.name = val["site_name"];
      if (val["site_unions"].length !== 0) {
        val["site_unions"].forEach((val1) => {
          let tunionnode = { name: "", children: [] };
          tunionnode.name = val1["union_name"];
          tsitenode["children"].push(tunionnode);
        });
        tmnodes.children.push(tsitenode);
      }
    });
    return tmnodes;
  };

  const GetExpandedValue = (nodes) => {
    let expandedValue = [];
    nodes.forEach((val) => {
      if (val["children"].length !== 0) {
        expandedValue.push(val["id"]);
      }
    });
    return expandedValue;
  };

  useEffect(() => {
    ini_sites = GetSitesWithUnion(siteUnionClubs);
    SetSites(ini_sites);
    if((stackConvertors.site == null || stackConvertors.union == null) && !IsExistInFormItemList(ini_sites, stackConvertors.site)){
      if (ini_sites.length !== 0) {
        inisite = ini_sites[0]["value"];
      } else {
        inisite = "";
      }
      SetSite(inisite);
      SetUnionsStackConvertorBySite(inisite, null);
    }else{
      SetSite(stackConvertors.site);
      SetUnionsStackConvertorBySite2(stackConvertors.site);
    }
    ini_nodes = GetNodesForNoCheckBoxTreeView(siteUnionClubs);
    let ftnodex = flattenTree(ini_nodes);
    ini_expandednodes = GetExpandedValue(ftnodex);
    SetExpanded(ini_expandednodes);
    setTimeout(() => SetNodes(ftnodex), 300);

  }, [siteUnionClubs, stackConvertors]);

  const SetUnionsStackConvertorBySite = (psite, punion) => {
    let tunions = GetUnions(siteUnionClubs, psite);
    SetUnions(tunions);
    if (tunions.length > 0) {
      if(punion===null){
        SetUnion(tunions[0].value);
        let stacks = GetStackConvertor(
          psite,
          tunions[0].value,
          stackConvertors.value
        );
        SetStack(GetSortedStackList(stacks));
      }else{
        SetUnion(punion);
        let stacks = GetStackConvertor(
          psite,
          punion,
          stackConvertors.value
        );
        SetStack(GetSortedStackList(stacks));
      }
    } else {
      SetUnion("");
    }
  };

  const SetUnionsStackConvertorBySite2 = (psite) => {
    let tunions = GetUnions(siteUnionClubs, psite);
    SetUnions(tunions);
    if (tunions.length > 0) {
      if(IsExistInFormItemList(tunions, stackConvertors.union)){
        SetUnion(stackConvertors.union);
        let stacks = GetStackConvertor(
          psite,
          stackConvertors.union,
          stackConvertors.value
        );
        SetStack(GetSortedStackList(stacks));
      }else{
        SetUnion(tunions[0]["value"]);
        let stacks = GetStackConvertor(
          psite,
          tunions[0]["value"],
          stackConvertors.value
        );
        SetStack(GetSortedStackList(stacks));
      }
    } else {
      SetUnion("");
    }
  };

  const addSiteSelectOnChange = (event) => {
    SetSite(event.target.value);
    SetUnionsStackConvertorBySite(event.target.value, null);
  };

  const addUnionSelectOnChange = (event) => {
    SetUnion(event.target.value);
    let stacks = GetStackConvertor(site, event.target.value, stackConvertors.value);
    SetStack(GetSortedStackList(stacks));
  };

  const IsCheckIfExist = (vstack, nstack) => {
    let ret = false;
    nstack.forEach(ele=>{
      let unionstack1 = ele.value.split('|')[0];
      let unionstack2 = vstack.split('|')[0];
      if(unionstack1 === unionstack2){
        ret = true;
      }
    });
    return ret;
  };

  
  const GetStackConvertorString = (value) => {
    return value.split("|").join("  ~  ");
  };

  const AddButonOnClick = () => {
    let nstack = [...stack];
    if (unionstack === "" || realstack === "") {
      return;
    }
    let tm = {};
    tm.value = `${unionstack}|${realstack}`;
    tm.checked = false;
    if(IsCheckIfExist(tm.value, nstack)){

      SetUnionstack("");
      SetRealstack("1/2");
      ShowWarningToast("The union stack is already existed.")
      return;
    }

    SetIsEditable(true);
    SetIsAddstack(true);

    nstack.push(tm);

    let newStackConvertors = GetNewStackConvertor(
      stackConvertors.value,
      site,
      union,
      tm.value
    );
    let data = {
      id: 1,
      content: JSON.stringify(newStackConvertors),
    };

    UpdateStackConvertor(data).then((val) => {
      if (val) {
        SetStack(GetSortedStackList(nstack));
        dispatch({type:"set_easyseat_stack_convertor", payload:{
          value:newStackConvertors,
          site:site,
          union:union,
        }});
        ShowSuccessToast("Successfully added new stack.")
      }else{
        ShowFailToast("Failed added new stack.")
      }
      SetUnionstack("");
      SetRealstack("1/2");
      SetIsAddstack(false);
      SetIsEditable(false);
    });

  };

  const RemoveBtnOnClick = () => {

    SetRemoveAddstack(true);
    SetIsEditable(true);
    let nstack = [];
    stack.forEach((ele) => {
      if (!ele["checked"]) {
        nstack.push(ele["value"]);
      }
    });
    let newStackConvertors = GetNewStackConvertorByStacks(
      stackConvertors.value,
      site,
      union,
      nstack
    );
    let data = {
      id: 1,
      content: JSON.stringify(newStackConvertors),
    };
    UpdateStackConvertor(data).then((val) => {
      if (val) {
        SetStack(GetSortedStackList(nstack));
        dispatch({type:"set_easyseat_stack_convertor", payload:{
          value:newStackConvertors,
          site:site,
          union:union,
        }});
        ShowSuccessToast("Successfully removed the stack.")
      }else{
        ShowFailToast("Failed removed new stack.")
      }
      SetUnionstack("");
      SetRealstack("1/2");
      SetRemoveAddstack(false);
      SetIsEditable(false);
    });
  };
  const RealStackFormOnChange = (e) =>{
    SetRealstack(e.target.value);
  };

  const onSelect = (e) => {
    // console.log("onselect", e);
    // console.log(nodes);
  };

  const ArrowIcon = ({ isOpen, className }) => {
    const baseClass = "arrow";
    const classes = cx(
      baseClass,
      { [`${baseClass}--closed`]: !isOpen },
      { [`${baseClass}--open`]: isOpen },
      className
    );
    return <IoMdArrowDropright className={classes} />;
  };

  const TreeNodeOnClick = (val, element, level) =>{
    
    if(level ===2){
      let nsite = GetNodeElementByID(element.parent);
      SetSite(nsite.name)
      SetUnionsStackConvertorBySite(nsite.name, element.name);
      SetUnion(element.name)
    }
  }

  const GetNodeElementByID = (id) =>{
    let ret = null;

    nodes.forEach(ele=>{
      if(ele.id === id){
        ret = ele;
      }
    });
    return ret;
  }

  const GetElementName = (element, level, isSelected, isHalfSelected) => {

    if (level === 1) {
      let bgColor = "transparent";
      if (element.name === site) {
        bgColor = "darkblue";
      }
      return (
        <span
          className="name"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            borderBottom: "1px solid #ffffff54",
            background: `${bgColor}`,
            padding: "2px 8px 2px 8px",
            borderRadius: "4px",
          }}
        >
          <FontAwesome name="dice"></FontAwesome> {element.name}
        </span>
      );
    } else if (level == 2) {

      let bgColor2 = "transparent";
      if (element.name === union) {
        bgColor2 = "darkgreen";
      }
      return (
        <span
          className="name"
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            background: `${bgColor2}`,
            padding: "2px 8px 2px 8px",
            borderRadius: "4px",
          }}
          onClick={(val)=>TreeNodeOnClick(val, element, level)}
        >
          <FontAwesome name="globe"></FontAwesome> {element.name}
        </span>
      );
    } else {
    }
  };


  const GetMainElement = () => {
    if (nodes.length > 0) {
      return (
        <div className="checkbox">
          <TreeView
            data={nodes}
            aria-label="Checkbox tree"
            multiSelect
            propagateSelect
            propagateSelectUpwards
            togglableSelect
            onSelect={(e) => onSelect(e)}
            expandedIds={expanded}
            nodeRenderer={({
              element,
              isBranch,
              isExpanded,
              isSelected,
              isHalfSelected,
              getNodeProps,
              level,
              handleSelect,
              handleExpand,
            }) => {
              return (
                <div
                  {...getNodeProps({ onClick: handleExpand })}
                  style={{ marginLeft: 40 * (level - 1) }}
                >
                  {isBranch ? (
                    <ArrowIcon isOpen={isExpanded} />
                  ) : (
                    <span style={{ marginLeft: "21px" }}></span>
                  )}
                  {GetElementName(
                    element,
                    level,
                    isSelected,
                    isHalfSelected
                  )}
                </div>
              );
            }}
          ></TreeView>
        </div>
      );
    }
  };


  return (
    <div className="align-items-center" style={{ width: "fit-content" }}>
      <CRow>
        <CCol lg={3} style={{ padding: "1px" }}>
          <CCard className="mb-2 mt-2">
            <CCardHeader>
              <strong>Select A Union</strong>
            </CCardHeader>
            <CCardBody className="easyseat-text-align-left">
              <CFormSelect
                disabled= {iseditable}
                className="mb-2"
                label="Site:"
                options={sites}
                value={site}
                onChange={addSiteSelectOnChange}
              ></CFormSelect>
              <CFormSelect
                disabled= {iseditable}
                className="mb-2"
                label="Union:"
                options={unions}
                value={union}
                onChange={addUnionSelectOnChange}
              ></CFormSelect>
              <CFormLabel className="mt-2">Add a stake convertor</CFormLabel>
              <div style={{ display: "flex" }}>
                <CFormInput
                  disabled= {iseditable}
                  style={{ width: "47.5%" }}
                  placeholder="U-Stake"
                  value={unionstack}
                  onChange={(ret) => {
                    SetUnionstack(ret.target.value);
                  }}
                  required
                ></CFormInput>
                  <CFormSelect
                  disabled= {iseditable}
                  style={{ width: "50.5%" }}
                  options={GetRealStackValues()}
                  value={realstack}
                  onChange={(a) => {
                    RealStackFormOnChange(a);
                  }}
                />
              </div>

              <CLoadingButton
                disabled= {iseditable}
                className="mt-3 mb-1"
                style={{ width: "60%", marginLeft: "40%" }}
                color="info"
                loading={isaddstack}
                onClick={AddButonOnClick}
              >
                Add
              </CLoadingButton>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol lg={5} style={{ padding: "1px" }}>
          <CCard className="mb-2 mt-2">
            <CCardHeader>
              <FontAwesome name="dice" />
              <strong> Sites, Unions</strong>
            </CCardHeader>
            <CCardBody className="easyseat-text-align-left">{GetMainElement()}</CCardBody>
          </CCard>
        </CCol>
        <CCol lg={4} style={{ padding: "1px" }}>
          <CCard className="mb-2 mt-2">
            <CCardHeader>
              <strong>All Stakes In Union</strong>
            </CCardHeader>
            <CCardBody className="easyseat-text-align-left">
              <CListGroup flush>
                {stack.map((ele) => {
                  return (
                    <CListGroupItem key={ele["value"]}>
                      <CFormCheck
                        disabled= {iseditable}
                        label={GetStackConvertorString(ele["value"])}
                        onChange={(value) => {
                          ele["checked"] = value.target.checked;
                        }}
                      />
                    </CListGroupItem>
                  );
                })}
              </CListGroup>
              <CLoadingButton
                disabled= {iseditable}
                className="mt-3 mb-1"
                style={{ width: "60%", marginLeft: "40%" }}
                color="dark"
                loading={isremovestack}
                onClick={RemoveBtnOnClick}
              >
                Remove
              </CLoadingButton>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
};
export default React.memo(StackConvertorPage);
