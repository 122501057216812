export const fetchWrapperHttps = {
  get: requestHttps("GET"),
  post: requestHttps("POST"),
  put: requestHttps("PUT"),
  delete: requestHttps("DELETE"),
};

function requestHttpsForMain(method) {
  return (url, body) => {
    const requestOptions = {
      method,
      mode: "cors",
    };
    if (body) {
      requestOptions.body = JSON.stringify(body);
    }
    return fetch(url, requestOptions);
  };
}

function requestHttps(method) {
  return (url, body) => {
    const requestOptions = {
      method,
      mode: "cors",
      headers: {},
    };
    if (body) {
      requestOptions.headers["Content-Type"] = "application/json";
      requestOptions.body = JSON.stringify(body);
    }
    return fetch(url, requestOptions);
  };
}

export const LoginAPI = async (loginURL, username, password) =>
  await fetchWrapperHttps.get(`${loginURL}&login=${username}&pass=${password}`);
export const LoginAdminAPI = async (
  adminLoginURL,
  adim_username,
  admin_password
) =>
  await fetchWrapperHttps.get(
    `${adminLoginURL}&login=${adim_username}&pass=${admin_password}`
  );
