import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  AppContent,
  AppSidebar,
  AppFooter,
  AppHeader,
} from "../components/index";
import LoadingScreen from "react-loading-screen";
import { GetProfiles } from "../helpers/supabase";
import { GetProfileJSONObjectList, GetProfileIndex } from "../helpers/convert";
import {
  GetAllUsers,
  GetSitesUnionsClubs,
  GetStackConvertor,
} from "helpers/admin/supabase";
import { TYPE_ADMIN, TYPE_USER } from "../constant/constant";
import { GetAccessableSiteUnionClub } from "helpers";
import { GetUserAccess } from "helpers/admin/supabase";
import { ShowFailToast } from "helpers/toast-alert";
import { GetUserByUserName } from "helpers/admin/supabase";
const DefaultLayout = () => {

  const siteClubs = useSelector((state) => state.siteUnionClubState.data);
  const stackConvertors = useSelector(
    (state) => state.stackConvertorState.data
  );
  const user = useSelector((state) => state.login.user[0]);
  const users = useSelector((state) => state.usersChangeState.data);
  const [loading, SetLoading] = useState(true);
  const dispatch = useDispatch();
  
  useEffect(() => {
    
    if (siteClubs === null) {
      if (user.type === TYPE_ADMIN) {
        GetSitesUnionsClubs().then((val) => {
          if (val && val.length !== 0) {
            let v = JSON.parse(val[0].content);
            dispatch({
              type: "set_easyseat_sites_unions_clubs",
              payload: v,
            });
          }
        });
      } else {
        GetSitesUnionsClubs().then((val) => {
          if (val && val.length !== 0) {
            GetUserAccess(user.id).then((ret) => {
              if (ret && ret.length !== 0) {
                let accessable_site = JSON.parse(ret[0]["access_site"]);
                let accessable_suc = GetAccessableSiteUnionClub(
                  accessable_site,
                  JSON.parse(val[0].content)
                );
                dispatch({
                  type: "set_easyseat_sites_unions_clubs",
                  payload: accessable_suc,
                });
              } else {
                SetLoading(false);
                ShowFailToast(
                  "You can not access any clubs now. Please constact the administrator."
                );
                dispatch({
                  type: "set_easyseat_sites_unions_clubs",
                  payload: [],
                });
              }
            });
          }
        });
      }
    } else {
      if (!users && user.type === TYPE_ADMIN) {
        GetAllUsers().then((val) => {
          if (val) {
            dispatch({
              type: "set_easyseat_users",
              payload: val,
            });
            GetProfiles(user.id).then((data) => {
              if (data && siteClubs.length !== 0) {
                let jb  = GetProfileJSONObjectList(data, user.id, siteClubs);
                // console.log(jb);
                GetUserByUserName(user).then(val=>{
                  let curProfileIndex  = GetProfileIndex(val[0].profile_name, jb);
                  let tm = {};
                  tm["profiles"] = jb;
                  tm["currentindex"] = curProfileIndex;
  
                  dispatch({
                    type: "set_current_profile",
                    payload: curProfileIndex,
                  });
                  dispatch({
                    type: "set_profiles",
                    payload: jb,
                  });
      
  
                  if (stackConvertors === null) {
                    GetStackConvertor(1).then((data) => {
                      SetLoading(false);
                      if (data && siteClubs.length !== 0) {
                        let v = JSON.parse(data[0].content);
                        dispatch({
                          type: "set_easyseat_stack_convertor",
                          payload: {
                            value: v,
                            site: null,
                            union: null,
                          },
                        });
                      }
                    });
                  }
                })

              }
            });
          }
        });
      } else if (!users && user.type === TYPE_USER) {
        GetProfiles(user.id).then((data) => {
          if (data && siteClubs.length !== 0) {
            GetUserByUserName(user).then(val=>{

              let jb  = GetProfileJSONObjectList(data, user.id, siteClubs);
              let curProfileIndex  = GetProfileIndex(val[0].profile_name, jb);
                  
              dispatch({
                type: "set_current_profile",
                payload: curProfileIndex,
              });
              dispatch({
                type: "set_profiles",
                payload: jb,
              });
  
              if (stackConvertors === null) {
                GetStackConvertor(1).then((data) => {
                  SetLoading(false);
                  if (data && siteClubs.length !== 0) {
                    dispatch({
                      type: "set_easyseat_stack_convertor",
                      payload: {
                        value: JSON.parse(data[0].content),
                        site: null,
                        union: null,
                      },
                    });
                  }
                });
              }
            });
           
          }
        });
      } else {
      }
    }
  }, [user, siteClubs, stackConvertors, dispatch]);

  const GetDOMElementLoadingScreen = () => {
    return (
      <LoadingScreen
        loading={true}
        bgColor="rgba(0,0,0,0.9)"
        spinnerColor="#000000"
        logoSrc=""
        text=""
      >
        <div></div>
      </LoadingScreen>
    );
  };

  const getDefaultLayoutWidget = () => {
    if (!loading) {
      return (
        <>
          <AppSidebar />
          <div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
            <AppHeader />
            <div className="body flex-grow-1">
              <AppContent />
            </div>
            {/* <AppFooter /> */}
          </div>
        </>
      );
    } else {
      return <div>{GetDOMElementLoadingScreen()}</div>;
    }
  };

  return <>{getDefaultLayoutWidget()}</>;
};

export default React.memo(DefaultLayout);
