const initialState = {
    profiles: [],
    currentProfile:{},
    currentProfileIndex:0,
}
  
export const profilesReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'set_profiles':
        return {
            ...state,
            profiles : action.payload,
            // currentProfileIndex : action.payload.length - 1,
            currentProfile : Object.assign({}, action.payload[state.currentProfileIndex])
        };

    case 'set_profiles_and_currentindex':
        return {
            ...state,
            profiles : action.payload.profiles,
            currentProfileIndex : action.payload.currentidnex,
            currentProfile : Object.assign({}, action.payload.profiles[action.payload.currentidnex])
        };
        
    case 'set_current_profile':
        return {
            ...state,
            currentProfileIndex: action.payload,
            currentProfile : Object.assign({}, state.profiles[action.payload])
        };

    default:
        return state
}
}
