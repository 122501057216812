import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ShowWarningToast = (text) => {
  toast.warn(text, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
};

export const noFoundSymbolToast = () => {
  toast.warning("There is no such table. Please check again", {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
};

export const InsertSymbolToast = () => {
  toast.info("New record has been inserted just now", {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
  });
};

export const UpdateAdminSettingToast = () => {
  toast.info("Admin Setting has been updated just now", {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
  });
};

export const loginFailToast = () => {
  toast.error("Login Failed. Username and Password is incorrect", {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
  });
};

export const loginSuccessToast = () => {
  toast.success("Login Successful. You are welcome!", {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
  });
};

export const ShowEasySeatToast = (text, time) => {
  toast.success(text, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: time,
  });
};

export const ShowSuccessToast = (text) => {
  toast.success(text, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
};

export const ShowFailToast = (text) => {
  toast.error(text, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
};
