import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilStar,
  cilColorPalette,
  cilSettings,
  cilUserPlus,
} from "@coreui/icons";
import { CNavItem, CNavTitle } from "@coreui/react-pro";

const _nav = [
  {
    component: CNavTitle,
    name: "Home",
  },
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/home/dashboard",
    icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Edit Profile",
    to: "/home/profilesetting",
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Color & Sounds",
    to: "/home/playercolors",
    icon: <CIcon icon={cilColorPalette} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: "Manage",
  },
  {
    component: CNavItem,
    name: "Add/Del Player",
    to: "/home/addremoveplayer",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
  },
];

export default _nav;
