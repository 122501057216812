import {
  UNKNOWN,
  LIGHTBUDDY,
  BUDDY,
  MEGABUDDY,
  ULTRABUDDY,
  TT_VPIP,
  TT_DB,
  TT_CallTIME,
  TT_BP1,
  TT_BP3,
  TT_BP5,
  TT_BP7,
  PLO,
  PLO5,
  PLO6,
  NLHE,
} from "../constant/constant";
import { GetNewDefaultProfile } from "./trasform";

export const GetSupaBlinds = (blinds) => {
  let ret = [];
  ret.push(`${blinds["unknown"]["min"]}-${blinds["unknown"]["max"]}`);
  ret.push(`${blinds["lightbuddy"]["min"]}-${blinds["lightbuddy"]["max"]}`);
  ret.push(`${blinds["buddy"]["min"]}-${blinds["buddy"]["max"]}`);
  ret.push(`${blinds["megabuddy"]["min"]}-${blinds["megabuddy"]["max"]}`);
  ret.push(`${blinds["ultrabuddy"]["min"]}-${blinds["ultrabuddy"]["max"]}`);
  return ret.join(",");
};

export const GetSupaTableType = (tabletype) => {
  let ret = [];
  tabletype.forEach((v1) => {
    if (v1["selected"]) ret.push(v1["value"]);
  });
  return ret.join(",");
};

export const GetSupaPlayerType = (playertype) => {
  let ret = [];
  playertype["unknown"] ? ret.push(1) : ret.push(0);
  playertype["lightbuddy"] ? ret.push(1) : ret.push(0);
  playertype["buddy"] ? ret.push(1) : ret.push(0);
  playertype["megabuddy"] ? ret.push(1) : ret.push(0);
  playertype["ultrabuddy"] ? ret.push(1) : ret.push(0);
  return ret.join(",");
};


export const SupaProfileToJSonSettingObject = (profiledata) => {
  let profile = {};
  profile["user_nid"] = profiledata["user_nid"];
  profile["blinds"] = GetBlindsObject(profiledata["blinds"]);
  profile["gametype"] = GetGameTypeObject(profiledata["gametype"]);
  profile["name"] = profiledata["name"];
  profile["playertype"] = GetPlayerTypesObject(profiledata["playertype"]);
  profile["siteclubs"] = JSON.parse(profiledata["siteclubs"]);
  profile["tabletype"] = GetTableTypeObject(profiledata["tabletype"]);
  profile["empty_seat_offset"] = GetEmptySeatOffsetValueObject(profiledata["empty_seat_offset"]);
  profile["min_player_type"] = GetMinPlayerTypeValueObject(profiledata["min_player_type"]);
  profile["siteclubs"] = JSON.parse(profiledata["siteclubs"]);
  profile["avdsetting"] = JSON.parse(profiledata["avdsetting"]);
  profile["maxmin_players"] = JSON.parse(profiledata["maxmin_players"]);
  return profile;
};

export const GetMaxMinPlayers = (data) => {
  if(data === undefined) return {max:3, min:1};
  var maxmin = data.split(',');
  let ret = {};
  ret['max']  = parseInt(maxmin[0]);
  ret['min']  = parseInt(maxmin[1]);
  return ret;
}

export const GetProfileIndex = (profile_name, profiles) => {
  let index = 0;
  for(var i = 0; i < profiles.length; i++){
    if(profile_name === profiles[i].name){
      return i;
    }
  }
  return index;
}

export const GetProfileJSONObjectList = (profilesdata, user_id, siteClubs) => {
  let v = SupaProfileToJSonSettingObject(GetNewDefaultProfile(user_id, "Default", siteClubs));
  let profiles = [v];
  profilesdata.forEach((val) => {
    let jb = SupaProfileToJSonSettingObject(val);
    profiles.push(jb);
  });
  return profiles;
};


export const JSonSettingObjectToSupaProfile = (profiledata) => {
  let profile = {};
  profile["user_nid"] = profiledata["user_nid"];
  profile["blinds"] = GetSupaBlinds(profiledata["blinds"]);
  profile["gametype"] = GetSupaGameType(profiledata["gametype"]);
  profile["name"] = profiledata["name"];
  profile["playertype"] = GetSupaPlayerType(profiledata["playertype"]);
  profile["siteclubs"] = JSON.stringify(profiledata["siteclubs"]);
  profile["tabletype"] = GetSupaTableType(profiledata["tabletype"]);
  profile["empty_seat_offset"] = GetSupaEmptySeat(profiledata["empty_seat_offset"]);
  profile["min_player_type"] = GetSupaMinPlayerType(profiledata["min_player_type"]);
  profile["siteclubs"] = JSON.stringify(profiledata["siteclubs"]);
  profile["avdsetting"] = JSON.stringify(profiledata["avdsetting"]);
  profile["maxmin_players"] = JSON.stringify(profiledata["maxmin_players"]);
  return profile;
};



export const GetSupaGameType = (gametype) => {
  let val = 0;
  if(val==0){
    val = val + 1;
  }
  let ret = [];
  gametype.forEach((v1) => {
    if (v1["selected"]) ret.push(v1["value"]);
  });
  return ret.join(",");
};


export const GetSupaEmptySeat = (emptyseat) => {
  let ret = [];
  ret.push(emptyseat["unknown"]);
  ret.push(emptyseat["lightbuddy"]);
  ret.push(emptyseat["buddy"]);
  ret.push(emptyseat["megabuddy"]);
  ret.push(emptyseat["ultrabuddy"]);
  return ret.join(",");
};

export const GetSupaMinPlayerType = (min_player_type) => {
  let ret = [];
  ret.push(min_player_type["unknown"]);
  ret.push(min_player_type["lightbuddy"]);
  ret.push(min_player_type["buddy"]);
  ret.push(min_player_type["megabuddy"]);
  ret.push(min_player_type["ultrabuddy"]);
  return ret.join(",");
};

export const GetTableTypeObject = (tabletype) => {
  let ret = [
    {
      value: 0,
      text: TT_VPIP,
      selected: false,
    },
    {
      value: 1,
      text: TT_DB,
      selected: false,
    },
    {
      value: 2,
      text: TT_CallTIME,
      selected: false,
    },
    {
      value: 3,
      text: TT_BP1,
      selected: false,
    },
    {
      value: 4,
      text: TT_BP3,
      selected: false,
    },
    {
      value: 5,
      text: TT_BP5,
      selected: false,
    },
    {
      value: 6,
      text: TT_BP7,
      selected: false,
    },
  ];
  tabletype.split(",").forEach((v1) => {
    ret.forEach((v2) => {
      if (`${v2["value"]}` === v1) v2["selected"] = true;
    });
  });
  return ret;
};

export const GetGameTypeObject = (gametype) => {
  let ret = [
    {
      value: 0,
      text: PLO,
      selected: false,
    },
    {
      value: 1,
      text: PLO5,
      selected: false,
    },
    {
      value: 2,
      text: PLO6,
      selected: false,
    },
    {
      value: 3,
      text: NLHE,
      selected: false,
    },
  ];
  gametype.split(",").forEach((v1) => {
    ret.forEach((v2) => {
      if (`${v2["value"]}` === v1) {
        v2["selected"] = true;
      }
    });
  });
  return ret;
};



export const GetPlayerTypesObject = (playertype) => {
  let pls = playertype.split(",");
  let ret = {};
  let index = 0;
  pls.forEach((val) => {
    switch (index) {
      case 0:
        ret["unknown"] = val === "1" ? true : false;
        break;
      case 1:
        ret["lightbuddy"] = val === "1" ? true : false;
        break;
      case 2:
        ret["buddy"] = val === "1" ? true : false;
        break;
      case 3:
        ret["megabuddy"] = val === "1" ? true : false;
        break;
      default:
        ret["ultrabuddy"] = val === "1" ? true : false;
        break;
    }
    index++;
  });
  return ret;
};

export const GetProfileNames = (profiles) => {
  let ret = [];
  let index = 0;
  profiles.forEach((profile) => {
    let t = {};
    t["value"] = index;
    t["label"] = profile["name"];
    ret.push(t);
    index++;
  });
  return ret;
};

export const GetGameType = (profiles, curProfileIndex) => {
  let ret = [
    {
      value: 0,
      text: PLO,
      selected: false,
    },
    {
      value: 1,
      text: PLO5,
      selected: false,
    },
    {
      value: 2,
      text: PLO6,
      selected: false,
    },
    {
      value: 3,
      text: NLHE,
      selected: false,
    },
  ];
  if (profiles.length === 0) {
    return ret;
  }
  const gametype = profiles[curProfileIndex]["gametype"];
  gametype.split(",").forEach((v1) => {
    ret.forEach((v2) => {
      if (`${v2["value"]}` === v1) {
        v2["selected"] = true;
      }
    });
  });
  return ret;
};

export const GetMinPlayerTypeValueObject = (pls) => {
  let ret = {};
  if(pls === undefined) return ret;
  let index = 0;
  pls.split(",").forEach((val) => {
    let value = parseInt(val);
    switch (index) {
      case 0:
        ret[UNKNOWN] = value;
        break;
      case 1:
        ret[LIGHTBUDDY] = value;
        break;
      case 2:
        ret[BUDDY] = value;
        break;
      case 3:
        ret[MEGABUDDY] = value;
        break;
      default:
        ret[ULTRABUDDY] = value;
        break;
    }
    index++;
  });
  return ret;
};

export const GetEmptySeatOffsetValueObject = (pls) => {
  let ret = {};
  let index = 0;
  pls.split(",").forEach((val) => {
    let value = parseInt(val);
    switch (index) {
      case 0:
        ret[UNKNOWN] = value;
        break;
      case 1:
        ret[LIGHTBUDDY] = value;
        break;
      case 2:
        ret[BUDDY] = value;
        break;
      case 3:
        ret[MEGABUDDY] = value;
        break;
      default:
        ret[ULTRABUDDY] = value;
        break;
    }
    index++;
  });
  return ret;
};

export const GetBlindsObject = (pls) => {
  let ret = {};
  let index = 0;

  pls.split(",").forEach((val) => {
    let value = { min: 0, max: 9 };
    let tv = val.split("-");
    value["min"] = parseInt(tv[0]);
    value["max"] = parseInt(tv[1]);
    switch (index) {
      case 0:
        ret[UNKNOWN] = value;
        break;
      case 1:
        ret[LIGHTBUDDY] = value;
        break;
      case 2:
        ret[BUDDY] = value;
        break;
      case 3:
        ret[MEGABUDDY] = value;
        break;
      default:
        ret[ULTRABUDDY] = value;
        break;
    }
    index++;
  });
  return ret;
};

export const SetGameTypeForProfile = (value, profiles, curProfileIndex) => {
  if (profiles.length === 0) return "";
  let ret = [];
  value.forEach((a) => {
    ret.push(a["value"]);
  });
  profiles[curProfileIndex]["gametype"] = ret.join(",");
};

export const GetTableType = (profiles, curProfileIndex) => {
  let ret = [
    {
      value: 0,
      text: "VPIP",
      selected: false,
    },
    {
      value: 1,
      text: "DoubleBoard",
      selected: false,
    },
    {
      value: 2,
      text: "BombPot",
      selected: false,
    },
    {
      value: 3,
      text: "Call time",
      selected: false,
    },
  ];
  if (profiles.length === 0) {
    return ret;
  }
  const tabletype = profiles[curProfileIndex]["tabletype"];
  tabletype.split(",").forEach((v1) => {
    ret.forEach((v2) => {
      if (`${v2["value"]}` === v1) v2["selected"] = true;
    });
  });
  return ret;
};

export const SetTableTypeForProfile = (value, profiles, curProfileIndex) => {
  if (profiles.length === 0) return "";
  let ret = [];
  value.forEach((a) => {
    ret.push(a["value"]);
  });
  profiles[curProfileIndex]["tabletype"] = ret.join(",");
};

export const GetPlayerTypeCheckIfEnabled = (
  profiles,
  curProfileIndex,
  index
) => {
  if (profiles.length === 0) return false;
  let profile = profiles[curProfileIndex];
  let pls = profile["playertype"].split(",")[index];

  if (pls === "1") {
    return true;
  }
  return false;
};

export const GetPlayerTypesCheckIfEnabled = (profiles, curProfileIndex) => {
  if (profiles.length === 0) return false;
  let profile = profiles[curProfileIndex];
  let pls = profile["playertype"].split(",");
  let ret = {};
  let index = 0;
  pls.forEach((val) => {
    switch (index) {
      case 0:
        ret["unknown"] = val === "1" ? true : false;
        break;
      case 1:
        ret["lightbuddy"] = val === "1" ? true : false;
        break;
      case 2:
        ret["buddy"] = val === "1" ? true : false;
        break;
      case 3:
        ret["megabuddy"] = val === "1" ? true : false;
        break;
      default:
        ret["ultrabuddy"] = val === "1" ? true : false;
        break;
    }
    index++;
  });
  return ret;
};

export const SetPlayerTypeCheckIfEnabled = (
  profiles,
  curProfileIndex,
  value,
  index
) => {
  if (profiles.length === 0) return;
  let profile = profiles[curProfileIndex];

  let pls = profile["playertype"].split(",");

  if (value) {
    pls[index] = 1;
  } else {
    pls[index] = 0;
  }

  profile["playertype"] = pls.join(",");
};

export const GetBlindsValue = (profiles, curProfileIndex) => {
  let ret = {};
  if (profiles.length === 0) return ret;
  let profile = profiles[curProfileIndex];
  let pls = profile["blinds"].split(",");
  let index = 0;

  pls.forEach((val) => {
    let value = { min: 0, max: 9 };
    let tv = val.split("-");
    value["min"] = parseInt(tv[0]);
    value["max"] = parseInt(tv[1]);
    switch (index) {
      case 0:
        ret[UNKNOWN] = value;
        break;
      case 1:
        ret[LIGHTBUDDY] = value;
        break;
      case 2:
        ret[BUDDY] = value;
        break;
      case 3:
        ret[MEGABUDDY] = value;
        break;
      default:
        ret[ULTRABUDDY] = value;
        break;
    }
    index++;
  });
  return ret;
};

export const SetBlindsValue = (profiles, curProfileIndex, value, ind) => {
  if (profiles.length === 0) return;
  let profile = profiles[curProfileIndex];
  let ret = GetBlindsValue(profiles, curProfileIndex);
  switch (ind) {
    case 0:
      ret[UNKNOWN] = value;
      break;
    case 1:
      ret[LIGHTBUDDY] = value;
      break;
    case 2:
      ret[BUDDY] = value;
      break;
    case 3:
      ret[MEGABUDDY] = value;
      break;
    default:
      ret[ULTRABUDDY] = value;
      break;
  }

  profile["blinds"] = `${ret[UNKNOWN]["min"]}-${ret[UNKNOWN]["max"]},${ret[LIGHTBUDDY]["min"]}-${ret[LIGHTBUDDY]["max"]},${ret[BUDDY]["min"]}-${ret[BUDDY]["max"]},${ret[MEGABUDDY]["min"]}-${ret[MEGABUDDY]["max"]},${ret[ULTRABUDDY]["min"]}-${ret[ULTRABUDDY]["max"]}`;
};

export const GetEmptySeatOffsetValue = (profiles, curProfileIndex) => {
  let ret = {};
  if (profiles.length === 0) return ret;
  let profile = profiles[curProfileIndex];
  let pls = profile["empty_seat_offset"].split(",");
  let index = 0;

  pls.forEach((val) => {
    let value = parseInt(val);
    switch (index) {
      case 0:
        ret[UNKNOWN] = value;
        break;
      case 1:
        ret[LIGHTBUDDY] = value;
        break;
      case 2:
        ret[BUDDY] = value;
        break;
      case 3:
        ret[MEGABUDDY] = value;
        break;
      default:
        ret[ULTRABUDDY] = value;
        break;
    }
    index++;
  });
  return ret;
};

export const SetEmptySeatOffsetValue = (
  profiles,
  curProfileIndex,
  value,
  ind
) => {
  if (profiles.length === 0) return;
  let profile = profiles[curProfileIndex];
  let ret = GetBlindsValue(profiles, curProfileIndex);
  switch (ind) {
    case 0:
      ret[UNKNOWN] = value;
      break;
    case 1:
      ret[LIGHTBUDDY] = value;
      break;
    case 2:
      ret[BUDDY] = value;
      break;
    case 3:
      ret[MEGABUDDY] = value;
      break;
    default:
      ret[ULTRABUDDY] = value;
      break;
  }

  profile["blinds"] = `${ret[UNKNOWN]},${ret[LIGHTBUDDY]},${ret[BUDDY]["max"]},${ret[MEGABUDDY]},${ret[ULTRABUDDY]}`;
};
