export const GetClubs = (data, site_name, union_name) => {
  let ret = [];
  data.forEach((val) => {
    if (site_name === val["site_name"]) {
      val["site_unions"].forEach((val1) => {
        if (val1["union_name"] === union_name) {
          val1["union_clubs"]["club_name"].forEach((val2) => {
            let tm = {};
            tm["value"] = val2;
            tm["label"] = val2;
            ret.push(tm);
          });
        }
      });
    }
  });
  return ret;
};
export const GetSites = (data) => {
  let ret = [];
  data.forEach((val) => {
    let tm = {};
    tm["value"] = val["site_name"];
    tm["label"] = val["site_name"];
    ret.push(tm);
  });
  return ret;
};

export const GetSitesWithUnion = (data) => {
  let ret = [];
  data.forEach((val) => {
    if (val["site_unions"].length !== 0) {
      let tm = {};
      tm["value"] = val["site_name"];
      tm["label"] = val["site_name"];
      ret.push(tm);
    }
  });
  return ret;
};

export const GetUnions = (data, site_name) => {
  let ret = [];
  data.forEach((val) => {
    if (site_name === val["site_name"]) {
      val["site_unions"].forEach((val1) => {
        let tm = {};
        tm["value"] = val1["union_name"];
        tm["label"] = val1["union_name"];
        ret.push(tm);
      });
    }
  });
  return ret;
};
