import React from "react";
import { CRow, CCol, CFormLabel } from "@coreui/react-pro";

import TableTypeDBLogo from "../../assets/tabletype/DB.png";
import TableTypeCallTimeLogo from "../../assets/tabletype/Calltime.png";
import TableTypeVPIPLogo from "../../assets/tabletype/vpip.png";
import TableTypeBPLogo from "../../assets/tabletype/BP.png";
import PlayerLogo from "../../assets/tabletype/player.png";

import siteClubGGLogo from "../../assets/logos/clubgg.png";
import siteWinaLogo from "../../assets/logos/winamax.png";
// import siteGgpLogo from "../../assets/logos/ggpoker.png";
import siteGgpbLogo from "../../assets/logos/ggpokerblack.png";
import site888PokerLogo from "../../assets/logos/888poker.png";
import sitePkstarLogo from "../../assets/logos/pokerstarslogo.png";
import sitePPPLogo from "../../assets/logos/ppp_logo.png";
import {GetRealStakeWithDollar} from "../../helpers/trasform";

import {
  TT_BP1,
  TT_BP3,
  TT_BP5,
  TT_BP7,
  TT_CallTIME,
  TT_DB,
  TT_VPIP,
  PPP,
  WINAMAX,
  CLUBGG,
  POKERSTARS,
  S888POKER,
  TT_BP,
} from "constant/constant";
import { IsInteger } from "helpers";
import { RemoveSpaceInText } from "helpers";

const EasySeatItemRowWidgetNew = ({ a, pt, pColors }) => {
  let key = 0;
  const GetLogoImage = (site) => {
    if (site === PPP) {
      return sitePPPLogo;
    } else if (site === WINAMAX) {
      return siteWinaLogo;
    } else if (site === CLUBGG) {
      return siteClubGGLogo;
    } else if (site === POKERSTARS) {
      return sitePkstarLogo;
    } else if (site === S888POKER) {
      return site888PokerLogo;
    } else {
      return siteGgpbLogo;
    }
  };

  const GetPlayerTypeBlock = (key, type) => {
    if (type === "regular") return;
    if (!pt[type]) return;
    return (
      <CFormLabel
        key={key}
        style={{
          width: "16px",
          height: "16px",
          backgroundColor: pColors[type],
          margin: "0px 1px -2px 1px",
        }}
      ></CFormLabel>
    );
  };

  const GetTableTypeLogoImage = (tabletype) => {
    if (RemoveSpaceInText(tabletype) === RemoveSpaceInText(TT_VPIP)) {
      return TableTypeVPIPLogo;
    } else if (RemoveSpaceInText(tabletype) === RemoveSpaceInText(TT_BP)) {
      return TableTypeBPLogo;
    } else if (RemoveSpaceInText(tabletype) === RemoveSpaceInText(TT_DB)) {
      return TableTypeDBLogo;
    } else if (RemoveSpaceInText(tabletype) === RemoveSpaceInText(TT_CallTIME)) {
      return TableTypeCallTimeLogo;
    } else {
      return null;
    }
  };

  const GetFormLabel = (text) => {
    return (
      <CFormLabel style={{ margin: "0px 4px 0px 4px", fontSize: 14 }}>
        <strong>{GetValidTableName(text)}</strong>
      </CFormLabel>
    );
  };

  const GetFormGameTypeLabel = (text) => {
    return (
      <CFormLabel style={{ margin: "0px 4px 0px 4px", fontSize: 14 }}>
        <strong>{text}</strong>
      </CFormLabel>
    );
  };

  const GetFormClubLabel = (clubname) => {
    return (
      <CFormLabel style={{ margin: "0px 4px 0px 4px", fontSize: 14 }}>
        <strong>{GetValidClubName(clubname)}</strong>
      </CFormLabel>
    );
  };

  const GetValidClubName = (clubname) => {
    if (!clubname) return null;
    if (clubname.length > 15) {
      return clubname.substring(0, 15) + "..";
    } else {
      return clubname;
    }
  };

  const GetValidNickName = (nickname) => {
    if (nickname.length > 9) {
      return nickname.substring(0, 9) + "..";
    } else {
      return nickname;
    }
  };

  const GetTableTypeLogo = (key, tabletype) => {
    if (tabletype === null) return null;
    return (
      <img
        key={key}
        src={GetTableTypeLogoImage(tabletype)}
        alt="site"
        width="16px"
        height="16px"
        style={{ margin: "-4px 0px 0px 0px" }}
      />
    );
  };

  const GetPlayerImgElement = () => {
    return (
      <img
        src={PlayerLogo}
        width="18px"
        height="18px"
        style={{ margin: "-4px 2px 0px 2px" }}
      />
    );
  };

  const GetValidTableName = (tablename) => {
    if (tablename.length > 20) {
      return tablename.substring(0, 20) + "..";
    } else {
      return tablename;
    }
  };

  const GetDateTimeElement = (time) => {
    return (
      <CFormLabel
        style={{
          margin: "0px 8px 0px 8px",
          borderBottom: "solid #897853 1px",
          fontSize: 14,
        }}
      >
        <strong>{time}</strong>
      </CFormLabel>
    );
  };

  const GetLogoImgElement = (site) => {
    return (
      <img
        src={GetLogoImage(site)}
        alt="site"
        width="16px"
        height="16px"
        style={{ margin: "-4px 2px 0px 2px" }}
      />
    );
  };

  let ind = 0;
  return (
    <CRow className="easyseat-detail-item-row">
      <CCol
        lg={3}
        className="easyseat-detail-item-col"
        style={{ minWidth: "186px" }}
      >
        {GetDateTimeElement(a["created_at"])}
        {a["nids"].map((r) => {
          key = key + 1;
          return GetPlayerTypeBlock(key, a["playertypes"][r]);
        })}
      </CCol>

      <CCol
        lg={1}
        className="easyseat-detail-item-col"
        style={{ minWidth: "140px" }}
      >
        {GetLogoImgElement(a["site"])}
        {GetFormClubLabel(a["club"])}
      </CCol>
      <CCol
        lg={1}
        className="easyseat-detail-item-col"
        style={{ minWidth: "140px" }}
      >
        {a.realstack === ""
          ? GetFormGameTypeLabel(`${a["blinds"]}`)
          : GetFormGameTypeLabel(
              `${a["blinds"]}(${GetRealStakeWithDollar(a.realstack)})`
            )}
      </CCol>
      <CCol
        lg={1}
        className="easyseat-detail-item-col"
        style={{ minWidth: "130px" }}
      >
        {GetFormLabel(a["gametype"])}
        {a["tabletype"].map((r) => {
          key = key + 1;
          let typeLogo = GetTableTypeLogo(key, r);
          return typeLogo;
        })}
      </CCol>
      <CCol
        lg={1}
        className="easyseat-detail-item-col"
        style={{ minWidth: "200px" }}
      >
        {GetFormLabel(a["tablename"])}
      </CCol>
      <CCol lg={4} className="easyseat-detail-item-col">
        {GetPlayerImgElement()}
        <CFormLabel
          style={{
            margin: "0px 4px 0px 4px",
          }}
        >
          <strong>{`(${a["positions"].length}/${a["maxtablesize"]})`}</strong>
        </CFormLabel>
        {a["nids"].map((r) => {
          let pt1 = a["playertypes"][r];
          if (IsInteger(r)) {
            pt1 = a["playertypes"][parseInt(r)];
          }
          if (pt1 !== "regular" && pt[pt1]) {
            var nickname = a["nicknames"][ind];
            if (ind === a.nicknames.length) {
              key = key + 1;
              ind = ind + 1;
              return (
                <div style={{ display: "contents" }} key={key}>
                  <CFormLabel>
                    <strong>{GetValidNickName(nickname)}</strong>
                  </CFormLabel>
                </div>
              );
            } else {
              key = key + 1;
              ind = ind + 1;
              return (
                <div style={{ display: "contents" }} key={key}>
                  <CFormLabel>
                    <strong>{GetValidNickName(nickname)},</strong>
                  </CFormLabel>
                </div>
              );
            }
          } else {
            key = key + 1;
            ind = ind + 1;
          }
        })}
      </CCol>
    </CRow>
  );
};
export default React.memo(EasySeatItemRowWidgetNew);
