const initialState = {
  data: [
    {
      site_name: "PPP",
      site_unions: [
        {
          union_name: "Z-Capital",
          union_clubs: {
            club_name: ["ZClub1"],
          },
        },
        {
            union_name: "TurfTurf",
            union_clubs: {
              club_name: ["Pot Re Pot"],
            },
          },
      ],
    },
    {
        site_name: "888Poker",
        site_unions: [
          {
            union_name: "8Union",
            union_clubs: {
              club_name: ["8Club1"],
            },
          }
        ],
      },
      {
        site_name: "PokerStars",
        site_unions: [
          {
            union_name: "PSUnion1",
            union_clubs: {
              club_name: ["PS1Club1"],
            },
          },
          {
              union_name: "PSUnion2",
              union_clubs: {
                club_name: ["PS2Club1"],
              },
            },
        ],
      },
      {
        site_name: "Winamax",
        site_unions: [],
      },
  ],
}

export const siteUnionClubDefaultReducer = (state = initialState, action) => {
  switch (action.type) {
    case "set_easyseat_sites_unions_clubs_default":
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
