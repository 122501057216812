import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardHeader,
  CRow,
  CCol,
  CCardBody,
  CButtonGroup,
  CFormSelect,
  CLoadingButton,
  CFormInput,
} from "@coreui/react-pro";
import FontAwesome from "react-fontawesome";
import { ShowWarningToast } from "../../helpers/toast-alert";
import ConfirmRemoveDlg from "./widgets/ConfirmRemoveDlg";
import { useSelector, useDispatch } from "react-redux";
import { GetSites, GetUnions, GetClubs } from "helpers/admin/convert";
import { UpdateSitesUnionsClubs } from "helpers/admin/supabase";
import NoAccessCheckTreeviewWidget from "./widgets/NoAccessCheckTreeviewWidget";
import {SITE, UNION, CLUB, HIDDEN} from "constant/constant"

const AddRemoveSitePage = () => {
  const dispatch = useDispatch();
  const showConfirmDlg = useSelector(
    (state) => state.confirmDlgState.showConfirmDlg
  );
  const siteUnionClubs = useSelector((state) => state.siteUnionClubState.data);
  const site = useSelector(
    (state) => state.siteUnionClubState.selectedData.site
  );
  const union = useSelector(
    (state) => state.siteUnionClubState.selectedData.union
  );
  const club = useSelector(
    (state) => state.siteUnionClubState.selectedData.club
  );

  let ini_sites = [];
  let iniclubs = [];
  let iniunions = [];

  const [showConfirmDialog, SetShowConfirmDialog] = useState(HIDDEN);
  const [isShowConfirmYes, SetShowConfirmYes] = useState(0);
  const [iseditable, SetIsEditable] = useState(false);
  const [isupdating, SetIsUpdating] = useState(false);
  const [issiteremoving, SetIsSiteRemoving] = useState(false);
  const [isunionremoving, SetIsUnionRemoving] = useState(false);
  const [isclubremoving, SetIsClubRemoving] = useState(false);
  const [sites, SetSites] = useState(ini_sites);
  const [isaddsite, SetIsAddSite] = useState(false);
  const [clubs, SetClubs] = useState(iniclubs);
  const [isaddclub, SetIsAddClub] = useState(false);
  const [unions, SetUnions] = useState(iniunions);
  const [isaddunion, SetIsAddUnion] = useState(false);
  const [sitename, SetSitename] = useState("");
  const [unionname, Setunionname] = useState("");
  const [clubname, SetClubname] = useState("");

  useEffect(() => {
    let inisite = "";
    ini_sites = GetSites(siteUnionClubs);
    if (ini_sites.length !== 0) {
      inisite = ini_sites[0]["value"];
    }
    SetSites(ini_sites);
    if (site === "") {
      SetInitSiteUnionClub(inisite, "", "");
    } else {
      SetInitSiteUnionClub(site, union, club);
    }
  }, [siteUnionClubs, site, union, club]);

  const SetSiteSelectChange = (psite) => {
    let tunions = GetUnions(siteUnionClubs, psite);
    SetUnions(tunions);
    if (tunions.length > 0) {
      let tclubs = GetClubs(siteUnionClubs, psite, tunions[0]["value"]);
      SetClubs(tclubs);
      if (tclubs.length > 0) {
        dispatch({
          type: "set_easyseat_sites_unions_clubs_only_selected",
          payload: {
            site: psite,
            union: tunions[0]["value"],
            club: tclubs[0]["value"],
          },
        });
      } else {
        dispatch({
          type: "set_easyseat_sites_unions_clubs_only_selected",
          payload: {
            site: psite,
            union: tunions[0]["value"],
            club: "",
          },
        });
      }
    } else {
      dispatch({
        type: "set_easyseat_sites_unions_clubs_only_selected",
        payload: {
          site: psite,
          union: "",
          club: "",
        },
      });
    }
  };

  const IsExistSite = (sitename) => {
    let ret = false;

    sites.forEach((val) => {
      if (val["value"].toLowerCase() === sitename.toLowerCase()) {
        ret = true;
      }
    });
    return ret;
  };

  const IsExistUnion = (unionname) => {
    let ret = false;
    unions.forEach((val) => {
      if (val["value"].toLowerCase() === unionname.toLowerCase()) {
        ret = true;
      }
    });
    return ret;
  };

  const IsExistClub = (clubname) => {
    let ret = false;
    clubs.forEach((val) => {
      if (val["value"].toLowerCase() === clubname.toLowerCase()) {
        ret = true;
      }
    });
    return ret;
  };

  const SetInitSiteUnionClub = (psite, punion, pclub) => {
    let tunions = GetUnions(siteUnionClubs, psite);
    SetUnions(tunions);
    if (tunions.length > 0) {
      let tclubs = GetClubs(
        siteUnionClubs,
        psite,
        punion !== "" ? punion : tunions[0]["value"]
      );
      SetClubs(tclubs);
      if (tclubs.length > 0) {
        dispatch({
          type: "set_easyseat_sites_unions_clubs_only_selected",
          payload: {
            site: psite,
            union: punion !== "" ? punion : tunions[0]["value"],
            club: pclub !== "" ? pclub : tclubs[0]["value"],
          },
        });
      } else {
        dispatch({
          type: "set_easyseat_sites_unions_clubs_only_selected",
          payload: {
            site: psite,
            union: punion !== "" ? punion : tunions[0]["value"],
            club: "",
          },
        });
      }
    } else {
      dispatch({
        type: "set_easyseat_sites_unions_clubs_only_selected",
        payload: {
          site: psite,
          union: punion,
          club: "",
        },
      });
    }
  };

  const SetUnionSelectChange = (punion) => {
    let tclubs = GetClubs(siteUnionClubs, site, punion);
    SetClubs(tclubs);
    if (tclubs.length > 0) {
      dispatch({
        type: "set_easyseat_sites_unions_clubs_only_selected",
        payload: {
          site: site,
          union: punion,
          club: tclubs[0]["value"],
        },
      });
    } else {
      dispatch({
        type: "set_easyseat_sites_unions_clubs_only_selected",
        payload: {
          site: site,
          union: punion,
          club: "",
        },
      });
    }
  };

  const SetClubSelectChange = (pclub) => {
    dispatch({
      type: "set_easyseat_sites_unions_clubs_only_selected",
      payload: {
        site: site,
        union: union,
        club: pclub,
      },
    });
  };

  const addSiteSelectOnChange = (event) => {
    SetSiteSelectChange(event.target.value, "", "");
  };

  const addUnionSelectOnChange = (event) => {
    SetUnionSelectChange(event.target.value);
  };

  const addClubSelectOnChange = (event) => {
    SetClubSelectChange(event.target.value);
  };

  const SiteAddButonOnClick = () => {
    SetIsAddSite(true);
    SetIsEditable(true);
  };
  const SiteRemoveButonOnClick = () => {
    if (site !== "") {
      SetShowConfirmDialog(SITE);
    }
  };

  const SiteRemoveFunction = () => {
    SetIsSiteRemoving(true);
    let newdatas = [];
    siteUnionClubs.forEach((val) => {
      if (site !== val["site_name"]) {
        newdatas.push(val);
      }
    });
    UpdateSitesUnionsClubs({ id: 1, content: JSON.stringify(newdatas) }).then(
      (val) => {
        if (val) {
          dispatch({
            type: "set_easyseat_sites_unions_clubs",
            payload: newdatas,
          });
          let tsites = GetSites(newdatas);
          SetSiteSelectChange(tsites.length === 0 ? "" : tsites[0].value);
          SetIsSiteRemoving(false);
        }
      }
    );
  };



  const GetSitesUnionsClubsWithNewUnion = (siteUnionClubs, site, union) => {
    siteUnionClubs.forEach((val) => {
      if (val["site_name"] === site) {
        let tm = {};
        tm["union_name"] = union;
        tm["union_clubs"] = {};
        tm["union_clubs"]["club_name"] = [];
        // val["site_unions"].unshift(tm);
        val["site_unions"].push(tm);
      }
    });
    return [...siteUnionClubs];
  };

  const GetSitesUnionsClubsWithNewClub = (
    siteUnionClubs,
    site,
    union,
    club
  ) => {
    siteUnionClubs.forEach((val) => {
      if (val["site_name"] === site) {
        val["site_unions"].forEach((val1) => {
          if (val1["union_name"] === union) {
            val1["union_clubs"]["club_name"].push(club);
          }
        });
      }
    });
    return [...siteUnionClubs];
  };

  const GetSitesUnionsClubsWithoutClub = (
    siteUnionClubs,
    site,
    union,
    club
  ) => {
    siteUnionClubs.forEach((val) => {
      if (val["site_name"] === site) {
        val["site_unions"].forEach((val1) => {
          if (val1["union_name"] === union) {
            val1["union_clubs"]["club_name"] = val1["union_clubs"][
              "club_name"
            ].filter((val) => val !== club);
          }
        });
      }
    });
    return [...siteUnionClubs];
  };

  const SiteCreateButonOnClick = () => {
    if (sitename === "") {
      ShowWarningToast("Please type the site's name correctly.");
      return;
    }
    if (IsExistSite(sitename)) {
      ShowWarningToast("The site's name is already existed.");
      SetSitename("");
      return;
    }
    SetIsUpdating(true);
    SetIsEditable(true);
    let newdatas = [...siteUnionClubs];
    let sitedata = {
      site_name: sitename,
      site_unions: [],
    };
    newdatas.push(sitedata);

    UpdateSitesUnionsClubs({ id: 1, content: JSON.stringify(newdatas) }).then(
      (val) => {
        if (val) {
          dispatch({
            type: "set_easyseat_sites_unions_clubs",
            payload: newdatas,
          });
          dispatch({
            type: "set_easyseat_sites_unions_clubs_only_selected",
            payload: {
              site: sitename,
              union: "",
              club: "",
            },
          });
        }
        SetIsUpdating(false);
        SetIsAddSite(false);
        SetIsEditable(false);
        SetSitename("");
      }
    );
  };

  const SiteCancelButonOnClick = () => {
    SetIsAddSite(false);
    SetIsEditable(false);
    SetSitename("");
  };

  const UnionAddButonOnClick = () => {
    SetIsAddUnion(true);
    SetIsEditable(true);
  };
  const UnionRemoveFunction = () =>{
    if (site !== "" && union !== "") {
      dispatch({ type: "set", showConfirmDlg: true });
      SetIsUnionRemoving(true);
      siteUnionClubs.forEach((val) => {
        if (site === val["site_name"]) {
          let tmlist = val["site_unions"];
          let newtmlist = [];
          tmlist.forEach((val1) => {
            if (val1["union_name"] !== union) {
              newtmlist.push(val1);
            }
          });
          val["site_unions"] = newtmlist;
        }
      });
      UpdateSitesUnionsClubs({
        id: 1,
        content: JSON.stringify([...siteUnionClubs]),
      }).then((val) => {
        if (val) {
          dispatch({
            type: "set_easyseat_sites_unions_clubs",
            payload: [...siteUnionClubs],
          });
          let tunions = GetUnions(siteUnionClubs, site);
          SetUnionSelectChange(tunions.length === 0 ? "" : tunions[0].value);
          SetIsUnionRemoving(false);
        }
      });
    }
  }

  const UnionRemoveButonOnClick = () => {
    if (site !== "" && union !== "") {
      SetShowConfirmDialog(UNION)
    }
  };

  
  const UnionCreateButonOnClick = () => {
    if (unionname === "") {
      ShowWarningToast("Please type the union's name correctly.");
      return;
    }
    if (IsExistUnion(unionname)) {
      ShowWarningToast("The union's name already is existed.");

      return;
    }
    SetIsEditable(true);
    SetIsUpdating(true);
    let newdata = GetSitesUnionsClubsWithNewUnion(
      siteUnionClubs,
      site,
      unionname
    );
    UpdateSitesUnionsClubs({ id: 1, content: JSON.stringify(newdata) }).then(
      (val) => {
        if (val) {
          dispatch({
            type: "set_easyseat_sites_unions_clubs",
            payload: newdata,
          });
        }
        dispatch({
          type: "set_easyseat_sites_unions_clubs_only_selected",
          payload: {
            site: site,
            union: unionname,
            club: "",
          },
        });
        Setunionname("");
        SetIsUpdating(false);
        SetIsAddUnion(false);
        SetIsEditable(false);
      }
    );
  };
  const UnionCancelButonOnClick = () => {
    SetIsAddUnion(false);
    SetIsEditable(false);
  };

  const ClubAddButonOnClick = () => {
    SetIsAddClub(true);
    SetIsEditable(true);
  };

  const ClubRemoveFunction = () => {
    if (club !== "") {
      SetIsClubRemoving(true);
      let newdata = GetSitesUnionsClubsWithoutClub(
        siteUnionClubs,
        site,
        union,
        club
      );
      UpdateSitesUnionsClubs({ id: 1, content: JSON.stringify(newdata) }).then(
        (val) => {
          if (val) {
            dispatch({
              type: "set_easyseat_sites_unions_clubs",
              payload: newdata,
            });
            SetIsClubRemoving(false);
            let tclubs = GetClubs(siteUnionClubs, site, union);
            SetClubSelectChange(tclubs.length === 0 ? "" : tclubs[0].value);
          }
        }
      );
    }
  }

  const ClubRemoveButonOnClick = () => {
    if (club !== "") {
      SetShowConfirmDialog(CLUB)
    }
  };

  const GetConfirmDlg = () => {
    if (showConfirmDialog !== HIDDEN) {
      return <ConfirmRemoveDlg type={showConfirmDialog} GetConfirmRemoveDlgResult={GetConfirmRemoveDlgResult} />;
    }
  };

  const ClubCreateButonOnClick = () => {
    if (clubname === "") {
      ShowWarningToast("Please type the club's name correctly.");
      return;
    }
    if (IsExistClub(clubname)) {
      ShowWarningToast("The club's name already is existed.");
      SetClubname("");
      return;
    }
    SetIsEditable(true);
    SetIsUpdating(true);
    let newdata = GetSitesUnionsClubsWithNewClub(
      siteUnionClubs,
      site,
      union,
      clubname
    );

    UpdateSitesUnionsClubs({ id: 1, content: JSON.stringify(newdata) }).then(
      (val) => {
        if (val) {
          dispatch({
            type: "set_easyseat_sites_unions_clubs",
            payload: newdata,
          });
          dispatch({
            type: "set_easyseat_sites_unions_clubs_only_selected",
            payload: {
              site: site,
              union: union,
              club: clubname,
            },
          });
        }
        SetClubname("");
        SetIsUpdating(false);
        SetIsAddClub(false);
        SetIsEditable(false);
      }
    );
  };
  const ClubCancelButonOnClick = () => {
    SetIsAddClub(false);
    SetIsEditable(false);
  };

  const GetUnionFormDOMElement = () => {
    if (sites.length !== 0) {
      return (
        <CCard className="mb-2 mt-2">
          <CCardHeader>
            <FontAwesome name="dragon" />
            <strong> Add/Remove Union</strong>
          </CCardHeader>
          <CCardBody className="easyseat-text-align-left">
            <CFormSelect
              disabled={iseditable}
              className="mb-2"
              options={unions}
              value={union}
              onChange={addUnionSelectOnChange}
            ></CFormSelect>
            <CButtonGroup role="group" style={{ width: "100%" }}>
              <CLoadingButton
                disabled={iseditable}
                color="info"
                timeout={300}
                onClick={UnionAddButonOnClick}
              >
                Add
              </CLoadingButton>
              <CLoadingButton
                disabled={iseditable}
                color="dark"
                loading={isunionremoving}
                onClick={UnionRemoveButonOnClick}
              >
                Remove
              </CLoadingButton>
            </CButtonGroup>
            {isaddunion && (
              <div className="mt-3 mb-2">
                <CFormInput
                  className="mb-2"
                  type="text"
                  placeholder="Please enter the union's name"
                  value={unionname}
                  onChange={(ret) => {
                    Setunionname(ret.target.value);
                  }}
                  required
                />
                <CButtonGroup role="group" style={{ width: "100%" }}>
                  <CLoadingButton
                    color="success"
                    loading={isupdating}
                    onClick={UnionCreateButonOnClick}
                  >
                    Create
                  </CLoadingButton>
                  <CLoadingButton
                    color="dark"
                    timeout={300}
                    onClick={UnionCancelButonOnClick}
                  >
                    Cancel
                  </CLoadingButton>
                </CButtonGroup>
              </div>
            )}
          </CCardBody>
        </CCard>
      );
    }
  };

  const GetConfirmRemoveDlgResult = (val, isyes) => {
    if(!isyes) {
      SetShowConfirmDialog(HIDDEN);
      return;
    }
    if(val === SITE){
      SiteRemoveFunction();
      SetShowConfirmDialog(HIDDEN);
    }else if(val == UNION){
      UnionRemoveFunction();
      SetShowConfirmDialog(HIDDEN);
    }else if(val == CLUB){
      ClubRemoveFunction();
      SetShowConfirmDialog(HIDDEN);
    }else{
      UnionRemoveFunction();
      SetShowConfirmDialog(HIDDEN);
    }
  };



  const GetClubFormDOMElement = () => {
    if (unions.length !== 0) {
      return (
        <CCard className="mb-2 mt-2">
          <CCardHeader>
            <FontAwesome name="users" />
            <strong> Add/Remove Clubs</strong>
          </CCardHeader>
          <CCardBody>
            <CFormSelect
              disabled={iseditable}
              className="mb-2"
              options={clubs}
              value={club}
              onChange={addClubSelectOnChange}
            ></CFormSelect>
            <CButtonGroup role="group" style={{ width: "100%" }}>
              <CLoadingButton
                disabled={iseditable}
                color="info"
                timeout={300}
                onClick={ClubAddButonOnClick}
              >
                Add
              </CLoadingButton>
              <CLoadingButton
                disabled={iseditable}
                color="dark"
                loading={isclubremoving}
                onClick={ClubRemoveButonOnClick}
              >
                Remove
              </CLoadingButton>
            </CButtonGroup>
            {isaddclub && (
              <div className="mt-3 mb-2">
                <CFormInput
                  className="mb-2"
                  type="text"
                  placeholder="Please enter the club's name"
                  value={clubname}
                  onChange={(ret) => {
                    SetClubname(ret.target.value);
                  }}
                  required
                />

                <CButtonGroup role="group" style={{ width: "100%" }}>
                  <CLoadingButton
                    color="success"
                    loading={isupdating}
                    onClick={ClubCreateButonOnClick}
                  >
                    Create
                  </CLoadingButton>
                  <CLoadingButton
                    color="dark"
                    timeout={300}
                    onClick={ClubCancelButonOnClick}
                  >
                    Cancel
                  </CLoadingButton>
                </CButtonGroup>
              </div>
            )}
          </CCardBody>
        </CCard>
      );
    }
  };
  return (
    <div className="align-items-center" style={{ maxWidth: "800px" }}>
      {GetConfirmDlg()}
      <CRow>
        <CCol lg={5}>
          <CCard className="mb-2 mt-2">
            <CCardHeader>
              <FontAwesome name="globe" />
              <strong> Add/Remove Site</strong>
            </CCardHeader>
            <CCardBody className="easyseat-text-align-left">
              <CFormSelect
                disabled={iseditable}
                className="mb-2"
                options={sites}
                value={site}
                onChange={addSiteSelectOnChange}
              ></CFormSelect>
              <CButtonGroup role="group" style={{ width: "100%" }}>
                <CLoadingButton
                  disabled={iseditable}
                  color="info"
                  timeout={300}
                  onClick={SiteAddButonOnClick}
                >
                  Add
                </CLoadingButton>
                <CLoadingButton
                  disabled={iseditable}
                  color="dark"
                  loading={issiteremoving}
                  onClick={SiteRemoveButonOnClick}
                >
                  Remove
                </CLoadingButton>
              </CButtonGroup>
              {isaddsite && (
                <div className="mt-3 mb-2">
                  <CFormInput
                    className="mb-2"
                    type="text"
                    placeholder="Please enter the site's name"
                    value={sitename}
                    onChange={(ret) => {
                      SetSitename(ret.target.value);
                    }}
                    required
                  />
                  <CButtonGroup role="group" style={{ width: "100%" }}>
                    <CLoadingButton
                      color="success"
                      loading={isupdating}
                      onClick={SiteCreateButonOnClick}
                    >
                      Create
                    </CLoadingButton>
                    <CLoadingButton
                      color="dark"
                      timeout={300}
                      onClick={SiteCancelButonOnClick}
                    >
                      Cancel
                    </CLoadingButton>
                  </CButtonGroup>
                </div>
              )}
            </CCardBody>
          </CCard>
          <div>{GetUnionFormDOMElement()}</div>
          <div>{GetClubFormDOMElement()}</div>
        </CCol>
        <CCol lg={7}>
          <CCard className="mb-2 mt-2">
            <CCardHeader>
              <FontAwesome name="dice" />
              <strong> Sites, Unions and Clubs</strong>
            </CCardHeader>
            <CCardBody className="easyseat-text-align-left">
              {<NoAccessCheckTreeviewWidget />}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
};
export default React.memo(AddRemoveSitePage);
