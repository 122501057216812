import { LOGIN, LOGOUT, LOGUPDATE } from '../constant/constant'

const initialLoginState = {
    user : JSON.parse(localStorage.getItem('user'))
}

export const loginReducer = (state = initialLoginState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                user: action.payload
            }
        case LOGOUT:
            return {
                user : action.payload
            }
        case LOGUPDATE:
            return {
                user : action.payload
            }
        default:
            return state
    }
}