import React from "react";
import { CRow, CCol } from "@coreui/react-pro";

const ServerStatusPage1= () => {
  return (
    <div className="align-items-center" style={{ maxWidth: "800px" }}>
      <CRow>
        <CCol lg={12}>
          <iframe
              style={{width:"100%",  height:"300px"}}
              title="brutal.rs"
              src="http://brutal.rs:3001/status/default"
              >
          </iframe>
        </CCol>
      </CRow>
    </div>
  );
};

const ServerStatusPage = () => {
  return (
    <iframe
      className="easyseat-iframe "
      title="brutal.rs"
      src="https://status.brutal.rs"
      // src="https://www.pcgamespunch.com"
      sandbox="allow-scripts allow-same-origin allow-popups"
    >
  </iframe>
  );
};
export default React.memo(ServerStatusPage);
