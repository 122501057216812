import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { history } from "../helpers";

export { AdminPrivateRoute };

function AdminPrivateRoute({ children }) {
  const user = useSelector((x) => x.login.user);
  if (!user && user.type == 1) {
    return <Navigate to="/adminlogin" state={{ from: history.location }} />;
  }

  return children;
}
