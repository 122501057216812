import React from "react";
import {
  CInputGroup,
  CInputGroupText,
  CCard,
  CCardHeader,
  CCol,
  CCardBody,
  CButton,
} from "@coreui/react-pro";

const ConfirmRemoveUser = ({ GetConfirmRemoveDlgResult }) => {
  const CancelBtnOnClick = () => {
    GetConfirmRemoveDlgResult(false);
  };

  const CreateBtnOnClick = () => {
    GetConfirmRemoveDlgResult(true);
  };

  const getDOMElement = () => {
    return (
      <CCardBody>
        <div>
          <CButton
            className="mt-2"
            color="success"
            style={{
              width: "35%",
              marginLeft: "2%",
            }}
            onClick={CreateBtnOnClick}
          >
            Yes
          </CButton>
          <CButton
            className="mt-2"
            color="danger"
            style={{
              width: "35%",
              marginLeft: "20%",
            }}
            onClick={CancelBtnOnClick}
          >
            No
          </CButton>
        </div>
      </CCardBody>
    );
  };

  return (
    <div
      style={{
        position: "fixed",
        zIndex: "1000",
        width: "100%",
        height: "100%",
        backgroundColor: "#2f2f2f69",
      }}
    >
      <CCard className="easyseat-confirm-dlg">
        <CCardHeader style={{ padding: "18px" }}>
          <strong>Would you really remove this ?</strong>
        </CCardHeader>
        {getDOMElement()}
      </CCard>
    </div>
  );
};
export default React.memo(ConfirmRemoveUser);
