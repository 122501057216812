import React, { useEffect, useState } from "react";
import {
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CCard,
  CCardHeader,
  CRow,
  CCol,
  CCardBody,
  CLoadingButton,
  CFormSelect,
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "react-loading-screen";
import {
  GetPlayer,
  GetPlayerBynID,
  InsertNewPlayer,
  DeletePlayer,
  DeletePlayerBynID,
} from "../../helpers/supabase";
import { history } from "helpers";
import { GetSites, GetClubs, GetUnionBySiteAndClub } from "helpers/trasform";
import { PLAYER_TYPES_OPTIONS, REGULAR } from "constant/constant";
import FontAwesome from "react-fontawesome";
import {
  ShowSuccessToast,
  ShowFailToast,
  ShowWarningToast,
} from "../../helpers/toast-alert";
import ConfirmRemovePlayerDlg from "./ConfirmRemovePlayerDlg";


const AddRemovePlayerPage = () => {
  const dispatch = useDispatch();

  const siteUnionClubs = useSelector((state) => state.siteUnionClubState.data);

  if (siteUnionClubs.length === 0) {
    return (
      <CCard className="mb-2 mt-2">
        <CCardBody
          style={{ background: "darkred", borderRadius: "6px", color: "white" }}
        >
          <h4>
            <FontAwesome
              name="triangle-exclamation"
              size="lg"
              style={{ color: "yellow" }}
            ></FontAwesome>
            You can never any clubs now. Please have a contact with
            adminstrator.{" "}
          </h4>
        </CCardBody>
      </CCard>
    );
  }
  const sites = GetSites(siteUnionClubs);
  const [showConfirmDialog, SetShowConfirmDialog] = useState(false);

  let inisite = null;
  let iniclubs = null;
  let iniclub = null;
  let iniunion = null;
  let iniptitleAdd = null;
  let iniptitleDel = null;

  if (sites.length !== 0) {
    inisite = sites[0]["value"];
    iniclubs = GetClubs(siteUnionClubs, inisite);

    if (iniclubs.length !== 0) {
      iniclub = iniclubs[0]["value"];
      iniptitleAdd = "ID";
      iniptitleDel = "ID";
      iniunion = GetUnionBySiteAndClub(siteUnionClubs, inisite, iniclub);
    } else {
      iniptitleAdd = "Player Name";
      iniptitleDel = "Player Name";
    }
  }

  const [playertype1, SetPlayertype1] = useState(REGULAR);
  const [ptitleAdd, SetPtitleAdd] = useState(iniptitleAdd);
  const [ptitleDel, SetPtitleDel] = useState(iniptitleDel);
  const [playername1, SetPlayername1] = useState("");
  const [playername2, SetPlayername2] = useState("");

  const [adding, SetAdding] = useState(false);
  const [deleting, SetDeleting] = useState(false);
  const [site1, Setsite1] = useState(inisite);
  const [clubs1, Setclubs1] = useState(iniclubs);
  const [club1, Setclub1] = useState(iniclub);
  const [union1, SetUnion1] = useState(iniunion);
  const [site2, Setsite2] = useState(inisite);
  const [clubs2, Setclubs2] = useState(iniclubs);
  const [club2, Setclub2] = useState(iniclub);
  const [union2, SetUnion2] = useState(iniunion);

  useEffect(() => {
    if (history.location.pathname.includes("dashboard")) {
      dispatch({ type: "set", showProfile: true });
    } else {
      dispatch({ type: "set", showProfile: false });
    }
  }, [dispatch]);

  const addSiteSelectOnChange = (event) => {
    Setsite1(event.target.value);
    let clubs = GetClubs(siteUnionClubs, event.target.value);
    Setclubs1(clubs);
    if (clubs.length === 0) {
      Setclub1(null);
      SetPtitleAdd("Player Name");
      SetUnion1(null);
    } else {
      Setclub1(clubs[0]["value"]);
      let union = GetUnionBySiteAndClub(
        siteUnionClubs,
        event.target.value,
        clubs[0]["value"]
      );
      SetUnion1(union);
      SetPtitleAdd("ID");
    }
  };

  const delSiteSelectOnChange = (event) => {
    Setsite2(event.target.value);
    let clubs = GetClubs(siteUnionClubs, event.target.value);
    Setclubs2(clubs);
    if (clubs.length === 0) {
      Setclub2(null);
      SetPtitleDel("Player Name");
      SetUnion2(null);
    } else {
      Setclub2(clubs[0]["value"]);
      SetUnion2(
        GetUnionBySiteAndClub(
          siteUnionClubs,
          event.target.value,
          clubs[0]["value"]
        )
      );
      SetPtitleDel("ID");
    }
  };

  const addPlayerTypeOnChange = (event) => {
    SetPlayertype1(event.target.value);
  };

  const AddPlayerBtnOnClick = () => {

    var user = {};
    if (ptitleAdd !== "ID") {
      user = {
        nickname: playername1,
        onickname: playername1.toLowerCase(),
        playertype: playertype1,
        site: site1,
        setbyuser:"1"
      };
    } else {
      user = {
        nickname: playername1,
        playertype: playertype1,
        nid: playername1,
        site: site1,
        setbyuser:"1"
      };
    }
    SetAdding(true);
    if (user.nickname === "") {
      SetAdding(false);
      ShowWarningToast("Please type the player name.");
      return;
    }
    if (ptitleAdd === "ID") {
      GetPlayerBynID(user).then((ret) => {
        if (ret.length !== 0) {
          ShowWarningToast(
            `'${user.nickname}' already is existed! Please check again.`
          );
          ClearAddPlayerForm();
        } else {
          InsertNewPlayer(user).then((ret) => {
            if (ret === 1) {
              ShowSuccessToast(
                `Adding Success! '${user.nickname}' has been added just now.`
              );
              ClearAddPlayerForm();
            } else {
              ShowFailToast("Adding new player Failed! Please check again.");
              ClearAddPlayerForm();
            }
          });
        }
        SetAdding(false);
      });
    } else {
      GetPlayer(user).then((ret) => {
        if (ret.length !== 0) {
          ShowWarningToast(
            `'${user.nickname}' already is existed! Please check again.`
          );
          ClearAddPlayerForm();
        } else {
          InsertNewPlayer(user).then((ret) => {
            if (ret === 1) {
              ShowSuccessToast(
                `Adding Success! '${user.nickname}' has been added just now.`
              );
              ClearAddPlayerForm();
            } else {
              ShowFailToast("Adding new player Failed! Please check again.");
              ClearAddPlayerForm();
            }
          });
        }
        SetAdding(false);
      });
    }
  };
  const DeletePlayer = () => {
    var user = {
      nickname: playername2,
      onickname: playername2.toLowerCase(),
      nid: playername2,
      site: site2,
      union: union2,
    };
    SetDeleting(true);
    if (ptitleDel === "ID") {
      GetPlayerBynID(user).then((ret) => {
        // console.log(ret);
        if (ret.length === 0) {
          ShowWarningToast(
            `'${user.nickname}' is not existed! Please check again.`
          );
          ClearAddPlayerForm();
        } else {
          DeletePlayerBynID(user).then((ret) => {
            if (ret === 1) {
              ShowSuccessToast(
                `Deleting Success! '${user.nickname}' has been deleted just now.`
              );
              ClearDelPlayerForm();
            } else {
              ShowFailToast("Deleting this player Failed! Please check again.");
              ClearDelPlayerForm();
            }
          });
        }
        SetDeleting(false);
      });
    } else {
      GetPlayer(user).then((ret) => {
        // console.log(ret);
        if (ret.length === 0) {
          ShowWarningToast(
            `'${user.nickname}' is not existed! Please check again.`
          );
          ClearAddPlayerForm();
        } else {
          DeletePlayer(user).then((ret) => {
            if (ret === 1) {
              ShowSuccessToast(
                `Deleting Success! '${user.nickname}' has been deleted just now.`
              );
              ClearDelPlayerForm();
            } else {
              ShowFailToast("Deleting this player Failed! Please check again.");
              ClearDelPlayerForm();
            }
          });
        }
        SetDeleting(false);
      });
    }
  }
  const DelPlayerBtnOnClick = () => {
    var user = {
      nickname: playername2,
      onickname: playername2.toLowerCase(),
      nid: playername2,
      site: site2,
      union: union2,
    };
    if (user.nickname === "") {
      ShowWarningToast("Please type the player name.");
      return;
    }
    SetShowConfirmDialog(true);
  };

  const ClearAddPlayerForm = () => {
    SetPlayername1("");
  };
  const ClearDelPlayerForm = () => {
    SetPlayername2("");
  };

  const GetConfirmRemovePlayerDlg = () =>{
    if(showConfirmDialog){
      return (
        <ConfirmRemovePlayerDlg GetConfirmRemoveDlgResult = {GetConfirmRemoveDlgResult}/>
      );
    }
  }

  const GetConfirmRemoveDlgResult = (isyes) => {
    if(!isyes) {
      SetShowConfirmDialog(false);
      return;
    }
    SetShowConfirmDialog(false);
    DeletePlayer();
  };

  return (
    <div className="align-items-center" style={{ maxWidth: "800px" }}>
      {GetConfirmRemovePlayerDlg()}
      <CRow>
        <CCol lg={6}>
          <CCard className="mb-2 mt-2">
            <CCardHeader>
              <strong>Add New Player</strong>
            </CCardHeader>
            <CCardBody className="easyseat-text-align-left">
              <CFormSelect
                className="mb-2"
                label="Site:"
                options={sites}
                onChange={addSiteSelectOnChange}
              ></CFormSelect>

              <CInputGroup
                className="flex-nowrap mb-2"
                style={{ marginTop: "22px" }}
              >
                <CInputGroupText id="addon-wrapping">
                  {ptitleAdd}
                </CInputGroupText>
                <CFormInput
                  type="text"
                  value={playername1}
                  onChange={(ret) => {
                    SetPlayername1(ret.target.value);
                  }}
                  required
                />
              </CInputGroup>

              <CFormSelect
                className="mb-2"
                label="PlayerType:"
                options={PLAYER_TYPES_OPTIONS}
                value={playertype1}
                onChange={addPlayerTypeOnChange}
              ></CFormSelect>
              <CLoadingButton
                className="mt-3"
                color="info"
                loading={adding}
                style={{ marginLeft: "50%", width: "40%" }}
                onClick={AddPlayerBtnOnClick}
              >
                Add
              </CLoadingButton>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol lg={6}>
          <CCard className="mb-2 mt-2">
            <CCardHeader>
              <strong>Delete a Player</strong>
            </CCardHeader>
            <CCardBody className="easyseat-text-align-left">
              <CFormSelect
                className="mb-2"
                label="Site:"
                options={sites}
                onChange={delSiteSelectOnChange}
              ></CFormSelect>
              <CInputGroup
                className="flex-nowrap mb-2"
                style={{ marginTop: "22px" }}
              >
                <CInputGroupText id="addon-wrapping">
                  {ptitleDel}
                </CInputGroupText>
                <CFormInput
                  type="text"
                  value={playername2}
                  onChange={(ret) => {
                    SetPlayername2(ret.target.value);
                  }}
                  required
                />
              </CInputGroup>
              <CLoadingButton
                className="mt-3"
                color="dark"
                style={{ marginLeft: "50%", width: "40%" }}
                onClick={DelPlayerBtnOnClick}
                loading={deleting}
              >
                Delete
              </CLoadingButton>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <LoadingScreen
        loading={adding}
        bgColor="rgba(255,255,255,0.1)"
        spinnerColor="#000000"
      >
        <div></div>
      </LoadingScreen>
      <LoadingScreen
        loading={deleting}
        bgColor="rgba(255,255,255,0.1)"
        spinnerColor="#000000"
      >
        <div></div>
      </LoadingScreen>
    </div>
  );
};
export default React.memo(AddRemovePlayerPage);
