
const initialState = {
    data: [],
}
  
export const easyseatDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'set_easyseat_data':
        return {
            ...state,
            data : action.payload,
        };
    default:
        return state
}
}
