import React from 'react';
import { CContainer } from '@coreui/react-pro'
export { Page404 };

const Page404 = () => {

    return (
        <CContainer>
            <h1 style={{color:'darkred'}}>404</h1>
            <h4 style={{color:'darkred'}}>Oops! You're lost.</h4>
            <p className="text-muted float-left">The page you are looking for was not found.</p>
        </CContainer>

    )
}

export default React.memo(Page404)
