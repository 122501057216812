import {
  BLINDS,
  BUDDY,
  LIGHTBUDDY,
  MEGABUDDY,
  PLAYER_TYPES,
  ULTRABUDDY,
  UNKNOWN,
} from "constant/constant";
import { GetAdvMinBuddyValue } from "helpers/trasform";

export const IsValidMinPlayerType = (min_player_type, data, playertype) => {
  if (playertype[ULTRABUDDY]) {
    // filtering in ultrabuddy
    let ultraCount = min_player_type[ULTRABUDDY];
    let ultraTCount = 0;
    data["nicknames"].forEach((ele) => {
      if (data["playertypes"][ele] === ULTRABUDDY) {
        ultraTCount++;
      }
    });
    if (ultraTCount >= ultraCount) return true;
  }

  // filtering in megabuddy
  if (playertype[MEGABUDDY]) {
    let megaCount = min_player_type[MEGABUDDY];
    let megaTCount = 0;
    data["nids"].forEach((ele) => {
      if (data["playertypes"][ele] === ULTRABUDDY) {
        if (playertype[ULTRABUDDY]) megaTCount++;
      }
      if (data["playertypes"][ele] === MEGABUDDY) {
        megaTCount++;
      }
    });
    if (megaTCount >= megaCount) return true;
  }

  // filtering in buddy
  if (playertype[BUDDY]) {
    let buddyCount = min_player_type[BUDDY];
    let buddyTCount = 0;
    data["nids"].forEach((ele) => {
      if (data["playertypes"][ele] === ULTRABUDDY) {
        if (playertype[ULTRABUDDY]) buddyTCount++;
      }
      if (data["playertypes"][ele] === MEGABUDDY) {
        if (playertype[MEGABUDDY]) buddyTCount++;
      }
      if (data["playertypes"][ele] === BUDDY) {
        buddyTCount++;
      }
    });
    if (buddyTCount >= buddyCount) return true;
  }

  // filtering in lightbuddy
  if (playertype[LIGHTBUDDY]) {
    let lightCount = min_player_type[LIGHTBUDDY];
    let lightTCount = 0;
    data["nids"].forEach((ele) => {
      if (data["playertypes"][ele] === ULTRABUDDY) {
        if (playertype[ULTRABUDDY]) lightTCount++;
      }
      if (data["playertypes"][ele] === MEGABUDDY) {
        if (playertype[MEGABUDDY]) lightTCount++;
      }
      if (data["playertypes"][ele] === BUDDY) {
        if (playertype[BUDDY]) lightTCount++;
      }
      if (data["playertypes"][ele] === LIGHTBUDDY) {
        lightTCount++;
      }
    });
    if (lightTCount >= lightCount) return true;
  }

  // filtering in unknown
  if (playertype[UNKNOWN]) {
    let unknownCount = min_player_type[UNKNOWN];
    let unknownTCount = 0;
    data["nids"].forEach((ele) => {
      if (data["playertypes"][ele] === ULTRABUDDY) {
        if (playertype[ULTRABUDDY]) unknownTCount++;
      }
      if (data["playertypes"][ele] === MEGABUDDY) {
        if (playertype[MEGABUDDY]) unknownTCount++;
      }
      if (data["playertypes"][ele] === BUDDY) {
        if (playertype[BUDDY]) unknownTCount++;
      }
      if (data["playertypes"][ele] === LIGHTBUDDY) {
        if (playertype[LIGHTBUDDY]) unknownTCount++;
      }
      if (data["playertypes"][ele] === UNKNOWN) {
        unknownTCount++;
      }
    });
    if (unknownTCount >= unknownCount) return true;
  }

  return false;
};

export const IsValidEmptySeatOffset = (
  max_table_size,
  position,
  empty_seats,
  emtpy_offset
) => {
  let ret = false;
  if (emtpy_offset == "0" || emtpy_offset == 0) return true;

  // updated
  let pos = parseInt(position);
  let maxTableSize = parseInt(max_table_size);
  emtpy_offset = parseInt(emtpy_offset) + 2;

  let possible_empty_seats = [];

  if (pos + emtpy_offset > maxTableSize) {
    for (let i = pos + 1; i <= maxTableSize; i++) {
      possible_empty_seats.push(`${i}`);
    }
    for (let i = 1; i < pos + emtpy_offset - maxTableSize + 1; i++) {
      if (possible_empty_seats.length == maxTableSize - 1) break;
      possible_empty_seats.push(`${i}`);
    }
  } else {
    for (let i = pos + 1; i <= pos + emtpy_offset; i++) {
      possible_empty_seats.push(`${i}`);
    }
  }
  possible_empty_seats.forEach((ele) => {
    if (empty_seats.includes(ele)) {
      ret = true;
    }
  });

  return ret;
};

export const IsValidForAdvancedSetting = (nicknames, playertypes, minvalue) => {
  let min_buddy = minvalue["mbuddy1"];
  let min_mega_buddy = minvalue["mbuddy2"];

  let cnt_buddy = 0;
  let cnt_mega_buddy = 0;

  nicknames.forEach((ele) => {
    let pt = playertypes[ele];

    if (PLAYER_TYPES[pt] >= 3) {
      cnt_mega_buddy++;
    }

    if (PLAYER_TYPES[pt] >= 2) {
      cnt_buddy++;
    }
  });

  if (cnt_mega_buddy >= min_mega_buddy) {
    return true;
  }

  if (cnt_buddy >= min_buddy) {
    return true;
  }

  return false;
};

export const DoValidSiteAndClubs = (profile, siteUionClubs, site, union) => {
  let ret = null;
  console.log("DD");
  if (union !== "") {
    let club = GetClubBySiteAndUnion(siteUionClubs, site, union);
    if (club === null) return ret;
    profile["siteclubs"].forEach((ele) => {
      if (ele["site_name"] === site) {
        if (ele["club_names"].includes(club[0])) {
          ret = club[0];
        }
      }
    });
  } else {
    profile["siteclubs"].forEach((ele) => {
      if (ele["site_name"] === site) {
        if(ele["club_names"].length == 0) {
          ret = null;
        }
        else{
          if (ele["club_names"][0] === site) {
            ret = site;
          }
        }
      }
    });
  }

  return ret;
};

export const IsValidMaxMinPlayers = (nids, maxminProfile) => {
  let count = nids.length;
  let max = maxminProfile["max"];
  let min = maxminProfile["min"];
  if (count >= min && count <= max) {
    return true;
  } else {
    return false;
  }
};

export const RemoveSpaceInText = (text) => {
  return text.replace(" ", "").toUpperCase();
};

export const IsIncludeTableType = (tabletypes, tabletype) => {
  let ret = false;
  tabletypes.forEach((ele) => {
    let tele = RemoveSpaceInText(ele);
    let tt = RemoveSpaceInText(tabletype);
    if (tele === tt) {
      ret = true;
    }
  });
  return ret;
};

export const IsValidEasySeatData = (
  data,
  profile,
  siteUionClubs,
  realStacks
) => {
  // console.log("a");
  let ret = true;
  let isvalid_for_tabletype = false;
  if (data["tabletype"] === "") return false;
  profile["tabletype"].forEach((ele) => {
    if (!ele["selected"]) {
      if (IsIncludeTableType(data["tabletype"], ele["text"])) {
        isvalid_for_tabletype = true;
      }
    }
  });

  if (isvalid_for_tabletype) return false;

  // check bombpothand
  let isvalid_for_bombpothand = false;
  if (data["bombpothand"] === undefined) {
    isvalid_for_bombpothand = true;
  }

  if (data["bombpothand"] === null) {
    isvalid_for_bombpothand = true;
  }

  if (data["bombpothand"] === "") {
    isvalid_for_bombpothand = true;
  }

  profile["tabletype"].forEach((ele) => {
    if (ele["selected"]) {
      if (ele["text"].includes("BombPot")) {
        let sv = parseInt(ele["text"].split(" ")[1].substring(2, 3));
        let bph = parseInt(data["bombpothand"]);
        if (sv === 1) {
          if (sv === bph) {
            data["tabletype"].push("BombPot");
            isvalid_for_bombpothand = true;
          }
        }
        if (sv === 3) {
          if (sv === bph) {
            data["tabletype"].push("BombPot");
            isvalid_for_bombpothand = true;
          }
        }
        if (sv === 5) {
          if (sv === bph) {
            data["tabletype"].push("BombPot");
            isvalid_for_bombpothand = true;
          }
        }
        if (sv === 7) {
          if (sv <= bph) {
            data["tabletype"].push("BombPot");
            isvalid_for_bombpothand = true;
          }
        }
      }

      if (IsIncludeTableType(data["tabletype"], ele["text"])) {
        isvalid_for_tabletype = true;
      }
    }
  });
  if (!isvalid_for_bombpothand) return false;

  // gametype filter
  if (data["gametype"] === "") return false;
  profile["gametype"].forEach((ele) => {
    if (ele["text"] === data["gametype"]) {
      ret = ele["selected"];
    }
  });

  if (!ret) return ret;

  if (!IsValidMaxMinPlayers(data["nids"], profile["maxmin_players"]))
    return false;

  // player type and blinds filter
  let playertype1;
  if (data["union"] === "") {
    playertype1 = data["playertypes"][data["nid"]];
  } else {
    playertype1 = data["playertypes"][data["nid"]];
  }
  if (!profile["playertype"][playertype1]) return false;

  // site and clubs when site has no clubs
  let blindvalue = -1;
  let club = DoValidSiteAndClubs(
    profile,
    siteUionClubs,
    data["site"],
    data["union"]
  );
  if (club === null) return false;

  if (data["union"] === "") {
    data["club"] = club;
    blindvalue = GetBlindsIndex(data["blinds"]);
  } else {
    data["club"] = club;
    let rblind = GetRBlindWithRealStack(
      data["site"],
      data["union"],
      data["blinds"],
      realStacks
    );
    blindvalue = GetBlindsIndex(rblind);
    data.realstack = rblind;
    if (blindvalue === -1) {
      blindvalue = GetBlindsIndex(data["blinds"]);
      data.realstack = data["blinds"];
    }
  }

  if (profile["blinds"][playertype1]["min"] > blindvalue) return false;
  if (profile["blinds"][playertype1]["max"] < blindvalue) return false;

  // advanced setting
  let minvalue = GetAdvMinBuddyValue(
    profile["avdsetting"],
    data["site"],
    club,
    blindvalue
  );

  if (
    !IsValidMinPlayerType(
      profile["min_player_type"],
      data,
      profile["playertype"]
    )
  )
    return false;

  if (
    !IsValidForAdvancedSetting(data["nicknames"], data["playertypes"], minvalue)
  ) {
    return false;
  }
  return ret;
};

export const GetClubBySiteAndUnion = (siteUionClubs, site, union) => {
  let ret = null;
  siteUionClubs.forEach((val) => {
    if (site === val["site_name"]) {
      if (val["site_unions"].length === 0) return "";
      val["site_unions"].forEach((val2) => {
        if (union === val2["union_name"]) {
          ret = val2["union_clubs"]["club_name"];
        }
      });
    }
  });
  return ret;
};

export const GetRBlindWithRealStack = (site, union, blind, realstacks) => {
  let realstack = "";

  realstacks.value.forEach((ele) => {
    if (ele.site_name === site) {
      ele.site_unions.forEach((ele1) => {
        if (ele1.union_name === union) {
          ele1.stackconvertors.forEach((ele2) => {
            let rbs = ele2.split("|");
            if (rbs[0] === blind) {
              realstack = rbs[1];
            }
          });
        }
      });
    }
  });
  return realstack;
};

export const GetBlindsIndex = (value) => {
  return BLINDS.indexOf(value);
};

export const IsValidEasySeatDataOld = (
  data,
  profile,
  siteUionClubs,
  realStacks
) => {
  let ret = true;
  let isvalid_for_tabletype = false;

  if (data["tabletype"] === "") return false;
  profile["tabletype"].forEach((ele) => {
    if (!ele["selected"]) {
      if (data["tabletype"].includes(ele["text"])) {
        isvalid_for_tabletype = true;
      }
    }
  });

  if (isvalid_for_tabletype) return false;

  // gametype filter
  if (data["gametype"] === "") return false;
  profile["gametype"].forEach((ele) => {
    if (ele["text"] === data["gametype"]) {
      ret = ele["selected"];
    }
  });

  if (!ret) return ret;

  // player type and blinds filter
  let playertype1;
  if (data["union"] === "") {
    playertype1 = data["playertypes"][data["nid"]];
  } else {
    playertype1 = data["playertypes"][data["nid"]];
  }
  if (!profile["playertype"][playertype1]) return false;

  // site and clubs when site has no clubs
  let blindvalue = -1;
  let club = DoValidSiteAndClubs(
    profile,
    siteUionClubs,
    data["site"],
    data["union"]
  );
  if (club === null) return false;

  if (data["union"] === "") {
    data["club"] = club;
    blindvalue = GetBlindsIndex(data["blinds"]);
  } else {
    data["club"] = club;
    let rblind = GetRBlindWithRealStack(
      data["site"],
      data["union"],
      data["blinds"],
      realStacks
    );
    blindvalue = GetBlindsIndex(rblind);
    data.realstack = rblind;
    if (blindvalue === -1) {
      blindvalue = GetBlindsIndex(data["blinds"]);
      data.realstack = data["blinds"];
    }
  }

  if (profile["blinds"][playertype1]["min"] > blindvalue) return false;
  if (profile["blinds"][playertype1]["max"] < blindvalue) return false;

  // advanced setting
  let minvalue = GetAdvMinBuddyValue(
    profile["avdsetting"],
    data["site"],
    club,
    blindvalue
  );

  if (!IsValidMinPlayerType(profile["min_player_type"], data)) return false;

  if (
    !IsValidForAdvancedSetting(data["nicknames"], data["playertypes"], minvalue)
  ) {
    return false;
  }

  if (
    !IsValidEmptySeatOffset(
      data["maxtablesize"],
      data["position"],
      data["emptyseats"],
      profile["empty_seat_offset"][playertype1]
    )
  ) {
    return false;
  }

  return ret;
};
