import React, { useEffect, useState } from "react";
import {
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CCard,
  CCardHeader,
  CRow,
  CCol,
  CCardBody,
  CLoadingButton,
  CFormSelect,
  CSpinner,
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "react-loading-screen";
import {
  ShowSuccessToast,
  ShowFailToast,
  ShowWarningToast,
} from "../../helpers/toast-alert";
import { PASSWORD_LENGTH } from "constant/constant";
import {
  DeleteUser,
  GetAllUsers,
  InsertUser,
} from "helpers/admin/supabase";
import { GetUserFormSelectData } from "helpers/admin/transform";
import ConfirmRemoveUserDlg from "./widgets/ConfirmRemoveUserDlg";

const AddRemoveUserPage = () => {
  const dispatch = useDispatch();
  const ini_users = useSelector((state) => state.usersChangeState.data);
  const [username, SetUsername] = useState("");
  const [password, SetPassword] = useState("");
  const [cpassword, SetCpassword] = useState("");
  const [adding, SetAdding] = useState(false);
  const [deleting, SetDeleting] = useState(false);
  const [users, SetUsers] = useState(ini_users);
  const [deluserid, SetDeluserid] = useState(null);
  const [loading, SetLoading] = useState(true);
  const [showConfirmDialog, SetShowConfirmDialog] = useState(false);

  useEffect(() => {
    if (ini_users !== null && ini_users.length !== 0) {
      let tusers = GetUserFormSelectData(ini_users);
      SetUsers(tusers);
      SetDeluserid(tusers[0]["value"]);
      SetLoading(false);
    }
  }, [ini_users]);

  const IsExistUser = (users, username) => {
    let ret = false;
    users.forEach((val) => {
      if (val["username"] === username) {
        ret = true;
      }
    });
    return ret;
  };

  const AddUserBtnOnClick = () => {
    if (username === "" || password === "") {
      ShowWarningToast("Please type username and password correctly.");
      return;
    }
    if (password.length < PASSWORD_LENGTH) {
      ShowWarningToast(
        `The password's length should be more than ${PASSWORD_LENGTH}`
      );
      SetPassword("");
      SetCpassword("");
      return;
    }
    if (password !== cpassword) {
      ShowWarningToast(
        "The password and confrimation password did not match. Please retype them."
      );
      SetPassword("");
      SetCpassword("");
      return;
    }
    SetAdding(true);
    let user = {
      username: username,
      password: password,
      type: 1,
      profile_name: "Default",
    };
    if (IsExistUser(users, username)) {
      ShowFailToast("The username is already existed. Please check again.");
      SetUsername("");
      SetAdding(false);
    } else {
      InsertUser(user).then((ret) => {
        if (ret === 1) {
          ShowSuccessToast(
            `Adding new user Success! '${user.username}' has been added just now.`
          );
          GetAllUsers().then((ret) => {
            if (ret !== null && ret.length !== 0) {
              dispatch({
                type: "set_easyseat_users",
                payload: ret,
              });
              SetUsers(GetUserFormSelectData(ret));
            }
            SetAdding(false);
          });
        } else {
          ShowFailToast("Adding new user Failed! Please check again.");
          SetAdding(false);
        }
        SetUsername("");
        SetPassword("");
        SetCpassword("");
      });
    }
  };

  const DeleteUserAccount = () => {
    SetDeleting(true);
    if (deluserid !== null) {
      DeleteUser(deluserid).then((ret) => {
        if (ret) {
          GetAllUsers().then((ret) => {
            dispatch({
              type: "set_easyseat_users",
              payload: ret,
            });
            let tm = GetUserFormSelectData(ret);
            if (ret !== null && ret.length !== 0) {
              SetDeluserid(tm[0]["value"]);
            }
            SetUsers(tm);
            ShowSuccessToast(
              `Deleting user Success! the user has been deleted just now.`
            );
            SetDeleting(false);
          });
        } else {
          ShowFailToast("Deleting user Failed! Please check again.");
          SetDeleting(false);
        }
      });
    }
  }

  const DeleteUserBtnOnClick = () => {
    SetShowConfirmDialog(true);
  };
  const DelUserSelectOnChange = (a) => {
    SetDeluserid(a.target.value);
  };

  const GetConfirmRemovePlayerDlg = () =>{
    if(showConfirmDialog){
      return (
        <ConfirmRemoveUserDlg GetConfirmRemoveDlgResult = {GetConfirmRemoveDlgResult}/>
      );
    }
  }

  const GetConfirmRemoveDlgResult = (isyes) => {
    if(!isyes) {
      SetShowConfirmDialog(false);
      return;
    }
    SetShowConfirmDialog(false);
    DeleteUserAccount();
  };

  const GetMainElement = () => {
    if (!loading) {
      return (
        <div className="align-items-center" style={{ maxWidth: "850px" }}>
          {GetConfirmRemovePlayerDlg()}
          <CRow>
            <CCol xs={24} sm={12} lg={6}>
              <CCard className="mb-2 mt-2">
                <CCardHeader>
                  <strong>Add New User</strong>
                </CCardHeader>
                <CCardBody className="easyseat-text-align-left">
                  <CInputGroup
                    className="flex-nowrap mb-2"
                    style={{ marginTop: "22px" }}
                  >
                    <CFormInput
                      type="text"
                      value={username}
                      placeholder="Username"
                      onChange={(ret) => {
                        SetUsername(ret.target.value);
                      }}
                      required
                    />
                  </CInputGroup>

                  <CInputGroup
                    className="flex-nowrap mb-2"
                    style={{ marginTop: "22px" }}
                  >
                    <CFormInput
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(ret) => {
                        SetPassword(ret.target.value);
                      }}
                      required
                    ></CFormInput>
                  </CInputGroup>

                  <CInputGroup
                    className="flex-nowrap mb-2"
                    style={{ marginTop: "22px" }}
                  >
                    <CFormInput
                      type="password"
                      placeholder="Confirmed Password"
                      value={cpassword}
                      onChange={(ret) => {
                        SetCpassword(ret.target.value);
                      }}
                      required
                    ></CFormInput>
                  </CInputGroup>

                  <CLoadingButton
                    className="mt-3"
                    color="info"
                    loading={adding}
                    style={{ marginLeft: "50%", width: "40%" }}
                    onClick={AddUserBtnOnClick}
                  >
                    Add
                  </CLoadingButton>
                </CCardBody>
              </CCard>
            </CCol>
            <CCol xs={24} sm={12} lg={6}>
              <CCard className="mb-2 mt-2">
                <CCardHeader>
                  <strong>Delete A User</strong>
                </CCardHeader>
                <CCardBody className="easyseat-text-align-left">
                  <CFormSelect
                    className="mb-2"
                    label="Username:"
                    options={users}
                    onChange={DelUserSelectOnChange}
                  ></CFormSelect>

                  <CLoadingButton
                    className="mt-3"
                    color="dark"
                    loading={deleting}
                    style={{ marginLeft: "50%", width: "40%" }}
                    onClick={DeleteUserBtnOnClick}
                  >
                    Delete
                  </CLoadingButton>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <LoadingScreen
            loading={adding}
            bgColor="rgba(255,255,255,0.1)"
            spinnerColor="#000000"
          >
            <div></div>
          </LoadingScreen>
          <LoadingScreen
            loading={deleting}
            bgColor="rgba(255,255,255,0.1)"
            spinnerColor="#000000"
          >
            <div></div>
          </LoadingScreen>
        </div>
      );
    } else {
      return (
        <div className="d-flex p-3">
          <CSpinner color="danger" variant="grow" className="m-1" />
          <CSpinner color="danger" variant="grow" className="m-1" />
          <CSpinner color="danger" variant="grow" className="m-1" />
        </div>
      );
    }
  };

  return <>{GetMainElement()}</>;
};
export default React.memo(AddRemoveUserPage);
