import React, { useState } from "react";
import {
  CCard,
  CCardHeader,
  CCol,
  CCardBody,
  CFormSelect,
  CButton,
  CLoadingButton,
} from "@coreui/react-pro";
import { ShowSuccessToast, ShowWarningToast } from "helpers/toast-alert";
import LoadingScreen from "react-loading-screen";
import { useDispatch, useSelector } from "react-redux";
import { DeleteProfile, GetProfileNames } from "helpers";


const DelProfileDlg = () => {
  const user_nid = useSelector((state) => state.login.user[0].id);
  const profiles = useSelector((x) => x.profileState.profiles);
  const curProfileIndex = useSelector(
    (x) => x.profileState.currentProfileIndex
  );
  const [selectind, SetSelectind] = useState(curProfileIndex);
  const [deleting, SetDeleting] = useState(false);
  const dispatch = useDispatch();

  const DeleteBtnOnClick = () => {
    SetDeleting(true);
    let profilename = profiles[selectind]["name"];
    if (profilename.toLowerCase() === "default") {
      ShowWarningToast(`Can not delete the default profile.`);
      SetDeleting(false);
      return;
    }
    DeleteProfile(profiles[selectind]).then((val) => {
      if (val) {
        ShowSuccessToast(`Successfully deleted the profile "${profilename}"`);
        let dps = [...profiles];
        dps = dps.filter(
          (dp) => dp.name.toLowerCase() !== profilename.toLowerCase()
        );
        dispatch({
          type: "set_profiles",
          payload: dps,
        });
        dispatch({ type: "set", showDeleteProfileDlg: false });
        dispatch({ type: "set", disableProfile: false });
        dispatch({ type: "set_current_profile", payload: 0 });
      } else {
        ShowFailToast(`Failed deleting the profile '${profilename}' just.`);
      }
      SetDeleting(false);
    });
  };
  
  const GetLoadingScreen = () => {
    return (
      <LoadingScreen
        loading={deleting}
        bgColor="rgba(255,255,255,0.1)"
        spinnerColor="#000000"
      >
        <div></div>
      </LoadingScreen>
    );
  }

  const CancelBtnOnClick = () => {
    dispatch({ type: "set", showDeleteProfileDlg: false });
    dispatch({ type: "set", disableProfile: false });
  };
  const SelectProfileOnChange = (e) => {
    SetSelectind(e.target.value);
  };

  const getDOMElement = () => {
    return (
      <CCardBody>
      <CFormSelect
        options={GetProfileNames(profiles)}
        value={selectind}
        onChange={SelectProfileOnChange}
      ></CFormSelect>
      <div>
        <CLoadingButton
          className="mt-3"
          color="primary"
          loading={deleting}
          style={{
            width: "30%",
            marginLeft: "5%",
          }}
          onClick={DeleteBtnOnClick}
        >
          Delete
        </CLoadingButton>
        <CButton
          className="mt-3"
          color="danger"
          style={{
            width: "30%",
            marginLeft: "28%",
          }}
          onClick={CancelBtnOnClick}
        >
          Cancel
        </CButton>
      </div>
    </CCardBody>
    );
  }

  return (
    <CCol lg={12}>
      <CCard className="mb-2">
        <CCardHeader>
          <strong>Delete the Profile</strong>
        </CCardHeader>
        {getDOMElement()}
      </CCard>
      <hr />
      {GetLoadingScreen()}
    </CCol>
  );
};
export default React.memo(DelProfileDlg);
