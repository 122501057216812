
const initialState = {
  playerColors:{
    user_nid:"1",
    unknown: "#d38e8e",
    lightbuddy: "#d8db43",
    buddy: "#1b691e",
    megabuddy:"#45dbeb",
    ultrabuddy:"#c445eb"
  }
}
  
export const playerColorReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'set_player_color':
        return {
          ...state,
          playerColors : action.payload,
      }
      default:
        return state
    }
}