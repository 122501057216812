const initialState = {
  soundOnOff:true,
  sidebarShow: false,
  asideShow: false,
  theme: 'dark',
  showCreateNewProfileDlg:false,
  showDeleteProfileDlg:false,
  disableProfile:false,
  telegramOnOff:false,
  showProfile:true,
}
export const changeStateReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}