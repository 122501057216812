import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilCalculator,
  cilApple,
  cilUserPlus,
  cilBeachAccess,
  cilStar,
  cilSettings,
  cilColorPalette,
  cilSchool,
} from "@coreui/icons";
import { CNavItem, CNavTitle } from "@coreui/react-pro";

const _nav = [
  {
    component: CNavTitle,
    name: "Home",
  },
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/home/dashboard",
    icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: "Settings",
  },
  {
    component: CNavItem,
    name: "Edit Profile",
    to: "/home/profilesetting",
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Color & Sounds",
    to: "/home/playercolors",
    icon: <CIcon icon={cilColorPalette} customClassName="nav-icon" />,
  },
  ,
  {
    component: CNavTitle,
    name: "Manage",
  },
  {
    component: CNavItem,
    name: "Add/Del Player",
    to: "/home/addremoveplayer",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: "Admin Panel",
  },
  {
    component: CNavItem,
    name: "Add/Del User",
    to: "/home/addremoveuser",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Add/Del Site",
    to: "/home/addremovesite",
    icon: <CIcon icon={cilApple} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "User Access Site",
    to: "/home/useraccess",
    icon: <CIcon icon={cilBeachAccess} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Stake Convertor",
    to: "/home/stakeconvertor",
    icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Server Status",
    to: "/home/serverstatus",
    icon: <CIcon icon={cilSchool} customClassName="nav-icon" />,
  },
];

export default _nav;
