const initialState = {
    data: null,
};

export const stackConvertorReducer = (state = initialState, action) => {
  switch (action.type) {
    case "set_easyseat_stack_convertor":
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
