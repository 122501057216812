import TreeView, { flattenTree } from "react-accessible-treeview";
import { IoMdArrowDropright } from "react-icons/io";
import cx from "classnames";
import FontAwesome from "react-fontawesome";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { GetUnions, GetClubs } from "helpers/admin/convert";

const NoAccessCheckTreeviewWidget = () => {
  const dispatch = useDispatch();
  const siteUnionClubs = useSelector((state) => state.siteUnionClubState.data);
  const ssite = useSelector(
    (state) => state.siteUnionClubState.selectedData.site
  );
  const sunion = useSelector(
    (state) => state.siteUnionClubState.selectedData.union
  );
  const sclub = useSelector(
    (state) => state.siteUnionClubState.selectedData.club
  );
  const [expanded, SetExpanded] = useState([]);
  const [nodes, SetNodes] = useState([]);

  let ini_expandednodes = [];
  let ini_nodes = {};

  const GetNodesForNoCheckBoxTreeView = (siteUnionsClubs) => {
    let tmnodes = { name: "", children: [] };
    siteUnionsClubs.forEach((val) => {
      let tsitenode = { name: "", children: [] };
      tsitenode.name = val["site_name"];
      val["site_unions"].forEach((val1) => {
        let tunionnode = { name: "", children: [] };
        tunionnode.name = val1["union_name"];
        val1["union_clubs"]["club_name"].forEach((val2) => {
          let tclubnode = {};
          tclubnode.name = val2;
          tunionnode["children"].push(tclubnode);
        });
        tsitenode["children"].push(tunionnode);
      });
      tmnodes.children.push(tsitenode);
    });
    return tmnodes;
  };

  const GetExpandedValue = (nodes) => {
    let expandedValue = [];
    nodes.forEach((val) => {
      if (val["children"].length !== 0) {
        expandedValue.push(val["id"]);
      }
    });
    return expandedValue;
  };

  useEffect(() => {
    ini_nodes = GetNodesForNoCheckBoxTreeView(siteUnionClubs);
    let ftnodex = flattenTree(ini_nodes);
    ini_expandednodes = GetExpandedValue(ftnodex);
    SetExpanded(ini_expandednodes);
    setTimeout(() => SetNodes(ftnodex), 300);
  }, [siteUnionClubs, ssite, sunion, sclub]);

  const onSelect = (e) => {
    console.log("onselect", e);
    console.log(nodes);
  };

  const ArrowIcon = ({ isOpen, className }) => {
    const baseClass = "arrow";
    const classes = cx(
      baseClass,
      { [`${baseClass}--closed`]: !isOpen },
      { [`${baseClass}--open`]: isOpen },
      className
    );
    return <IoMdArrowDropright className={classes} />;
  };

  const TreeNodeOnClick = (val, element, level) => {
    if (level === 1) {
      let unions = GetUnions(siteUnionClubs, element.name);
      let clubs = GetClubs(
        siteUnionClubs,
        element.name,
        unions.length === 0 ? "" : unions[0].value
      );
      dispatch({
        type: "set_easyseat_sites_unions_clubs_only_selected",
        payload: {
          site: element.name,
          union: unions.length === 0 ? "" : unions[0].value,
          club: clubs.length === 0 ? "" : clubs[0].value,
        },
      });
    } else if (level === 2) {
      let nsite = GetNodeElementByID(element.parent);
      let clubs = GetClubs(siteUnionClubs, nsite.name, element.name);
      dispatch({
        type: "set_easyseat_sites_unions_clubs_only_selected",
        payload: {
          site: nsite.name,
          union: element.name,
          club: clubs.length === 0 ? "" : clubs[0].value,
        },
      });
    } else {
      let nunion = GetNodeElementByID(element.parent);
      let nsite = GetNodeElementByID(nunion.parent);
      dispatch({
        type: "set_easyseat_sites_unions_clubs_only_selected",
        payload: {
          site: nsite.name,
          union: nunion.name,
          club: element.name,
        },
      });
    }
  };

  const GetNodeElementByID = (id) => {
    let ret = null;

    nodes.forEach((ele) => {
      if (ele.id === id) {
        ret = ele;
      }
    });
    return ret;
  };

  const GetElementName = (element, level, isSelected, isHalfSelected) => {
    if (level === 1) {
      let bgColor = "transparent";
      if (element.name === ssite) {
        bgColor = "darkblue";
      }
      return (
        <span
          className="name"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            borderBottom: "1px solid #ffffff54",
            color: "white",
            background: `${bgColor}`,
          }}
          onClick={(val) => TreeNodeOnClick(val, element, level)}
        >
          <FontAwesome name="dice"></FontAwesome> {element.name}
        </span>
      );
    } else if (level == 2) {
      let bgColor2 = "transparent";
      if (element.name === sunion) {
        bgColor2 = "darkgreen";
      }
      return (
        <span
          className="name"
          style={{
            fontSize: "16px",
            fontWeight: "500",
            background: `${bgColor2}`,
          }}
          onClick={(val) => TreeNodeOnClick(val, element, level)}
        >
          <FontAwesome name="globe"></FontAwesome> {element.name}
        </span>
      );
    } else {
      let bgColor3 = "transparent";
      if (element.name === sclub) {
        bgColor3 = "darkred";
      }
      return (
        <span
          className="name"
          style={{ fontSize: "14px", background: `${bgColor3}` }}
          onClick={(val) => TreeNodeOnClick(val, element, level)}
        >
          <FontAwesome name="users"></FontAwesome> {element.name}
        </span>
      );
    }
  };

  const GetMainElement = () => {
    if (nodes.length > 0) {
      return (
        <div className="checkbox">
          <TreeView
            data={nodes}
            aria-label="Checkbox tree"
            multiSelect
            propagateSelect
            propagateSelectUpwards
            togglableSelect
            onSelect={(e) => onSelect(e)}
            expandedIds={expanded}
            nodeRenderer={({
              element,
              isBranch,
              isExpanded,
              isSelected,
              isHalfSelected,
              getNodeProps,
              level,
              handleSelect,
              handleExpand,
            }) => {
              return (
                <div
                  {...getNodeProps({ onClick: handleExpand })}
                  style={{ marginLeft: 40 * (level - 1) }}
                >
                  {isBranch ? (
                    <ArrowIcon isOpen={isExpanded} />
                  ) : (
                    <span style={{ marginLeft: "21px" }}></span>
                  )}
                  {GetElementName(element, level, isSelected, isHalfSelected)}
                </div>
              );
            }}
          ></TreeView>
        </div>
      );
    }
  };

  return <div>{GetMainElement()}</div>;
};
export default React.memo(NoAccessCheckTreeviewWidget);
