import React from 'react'
import {
  CAvatar,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react-pro'
import {
  cilSettings,
  cilUser,
  cilAccountLogout,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import userImg from '../../assets/images/avatars/user.png'
import FontAwesome from 'react-fontawesome'
import { useSelector } from 'react-redux'

const AppHeaderDropdown = (props) => {
  
  const loginUser = useSelector((state) => state.login.user);
  const onLogoutAction = props.onLogoutAction
  const onLogoutHandler = () =>{
    onLogoutAction()
  }

  return (
    <CDropdown variant="nav-item" alignment="end">
      <CDropdownToggle placement="bottom-end" caret={false}>
        <FontAwesome name = "user-secret" size="2x"/>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0">
        <CDropdownHeader className="bg-light dark:bg-white dark:bg-opacity-10 fw-semibold py-2" style={{textAlign:'center', fontSize:'16px'}}>
          User: {loginUser[0].username}
        </CDropdownHeader>
        <CDropdownItem href="/home/profilesetting">
          <CIcon icon={cilUser} className="me-2" />
          Profile
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem href="/home/setting">
          <CIcon icon={cilSettings} className="me-2" />
          Setting
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem href="/" onClick={onLogoutHandler}>
          <CIcon icon={cilAccountLogout} className="me-2" />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
