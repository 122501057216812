const initialState = {
  data:null,
  selectedData:{
    site:"",
    club:"",
    union:"",
  }
};

export const siteUnionClubReducer = (state = initialState, action) => {
  switch (action.type) {
    case "set_easyseat_sites_unions_clubs":
      return {
        ...state,
        data: action.payload,
      };
    case "set_easyseat_sites_unions_clubs_only_selected":

      return {
        ...state,
        selectedData: action.payload,
      };
    default:
      return state;
  }
};
