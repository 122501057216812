import React from "react";
import { CFooter } from "@coreui/react-pro";

const AppFooter = () => {
  return (
    <CFooter>
      <div>
        <a
          href="https://easy-seat.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          EasySeat
        </a>
        <span className="ms-1">&copy; 2023</span>
      </div>
      <div className="ms-auto">
        {/* <a
          href="https://easy-seat.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Kobi Petterson &amp; Gol Coco
        </a> */}
        <a
          href="https://easy-seat.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Kobi Petterson
        </a>
      </div>
    </CFooter>
  );
};

export default React.memo(AppFooter);
