import ProfileSettingPage from "./ProfileSettingPage";
import DashboardPage from "./DashboardPage";
import AddRemovePlayerPage from "./AddRemovePlayerPage";
import PlayerColorsPage from "./PlayerColorsPage";

export {
  AddRemovePlayerPage,
  ProfileSettingPage,
  PlayerColorsPage,
  DashboardPage,
};
