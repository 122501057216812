import React from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { fetchWrapperHttps, history } from "./helpers";
import { PrivateRoute } from "./routes";
import DefaultLayout from "./pages/DefaultLayout";
import { ToastContainer } from "react-toastify";
import Login from "./pages/Login";
import "./scss/style.scss";
// import axios from 'axios';

const App = () => {

  console.log("Gol Coco: 24/09/13 18:00");
  history.navigate = useNavigate();
  history.location = useLocation();

  const GetToastContainer = () => {
    return (
      <ToastContainer
        toastStyle={{ backgroundColor: "rgb(42 43 54 / 93%)", color: "white" }}
      />
    );
  };

  const GetRoutes = () => {
    return (
      <Routes>
        <Route
          exact
          path="/home/*"
          element={
            <PrivateRoute>
              <DefaultLayout />
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Login />} />
      </Routes>
    );
  };

  return <div>{GetToastContainer()}{GetRoutes()}</div>;

    
  // axios.get('https://pusheripc-default-rtdb.firebaseio.com/@ES-ATP/.json?orderBy="$key"&limitToLast=500&auth=8OG9LOB3w1VUw7zFrrDgA8oW16XVOqgAKobYsPLP').then(function (response) {
  //   console.log(response.data);
  // }).catch(function (error) {
  //     console.log(error);
  // });

  // axios.get('https://shriinvest.com/srimember/api/check-access/by-login-pass?_key=LNgKiTfuZL8rCAcwJehD&login=umesh&pass=1234@').then(function (response) {
  //   console.log(response);
  // }).catch(function (error) {
  //     console.log(error);
  // });


};

export default React.memo(App);
