import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { history } from "../helpers";

export { PrivateRoute };

function PrivateRoute({ children }) {
  const user = useSelector((x) => x.login.user);
  if (!user) {
    return <Navigate to="/login" state={{ from: history.location }} />;
  }

  return children;
}
